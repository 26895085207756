import React, {useState, useEffect} from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { requestBilling } from '../../../components/account/RequestHandlers';

const StyledContentDiv = styled.div`
	height: 100%;
	width: min(500px, 90vw);
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
    padding: 2em;
    margin: auto;
    a {
		text-decoration: none;
		color: grey;
		:hover {
			color: silver;
		}
	}
`;

export default function CanceledPurchase() {
    const history = useNavigate();
    const [remainingTime, setRemainingTime] = useState(20);

    const isUserManuallyEntered = document.referrer === '';

    const handleBillingSubmit = async () => {
		const response = await requestBilling();
		if (response.ok) {
			const result = await response.json();
			window.open(result.url, "_blank");
		} else {
			return false;
		};
	};

    useEffect(() => {
        const redirectTimer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
        }, 2000); 

        if (remainingTime === 0) {
            clearInterval(redirectTimer);
            window.location.href = "/account";
        }

        return () => clearInterval(redirectTimer);
    }, [history, remainingTime]);
    return(
        <>
            {isUserManuallyEntered ? <Navigate to={"/account"}/> :           
                <StyledContentDiv>
                    <p>
                        <b>Plan Purchase Canceled</b>
                    </p>
                    <p>
                        If you didn't find the payment method you would like to use or would like to pay via bank transfer please <a href='mailto:support@avatarneo.com'>contact us</a>.
                    </p>
                    <p><a href="/account">Click here to go back to account or you will be redirected in {remainingTime} seconds...</a></p>
                </StyledContentDiv>
            }
        </>
    )
}