import { StatusCodes } from "http-status-codes";

const endpoint = 'https://account.avatarneo.com';

// registration

const signUpEndpoint = endpoint + '/signup';
export const requestSignUp = async (payload) => {
    try {
        const response = await fetch(signUpEndpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
        });
        return response; 
    } catch (error) {
        console.log(error.message);
        return false;
    }
}

const verifyPhoneEndpoint = endpoint + '/verify';
export const requestVerifyPhone = async (payload) => {
    try {
        const response = await fetch(verifyPhoneEndpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
        });
        return response;
    } catch (error) {
        console.log(error.message);
        return false;
    }
}

// authentication

const signInEndpoint = endpoint + '/login';
export const requestSignIn = async (payload) => {
    try {
        const response = await fetch(signInEndpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
        });
        return response; 
    } catch (error) {
        console.log(error.message);
        return false;
    }  
};

const verifyEmailEndpoint = endpoint + '/auth/login';
export const requestVerifyEmail = async (payload) => {
    try {
        const response = await fetch(verifyEmailEndpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
        });
        return response;
    } catch (error) {
        console.log(error.message);
        return false;
    }
};

const forgotPasswordEndpoint = endpoint + '/forgot-password';
export const requestForgotPassword = async (payload) => {
    try {
        const response = await fetch(forgotPasswordEndpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
        });
        return response; 
    } catch (error) {
        console.log(error.message);
        return false;
    }  
};

const forgotPasswordVerifyEndpoint = endpoint + '/forgot-password/login';
export const requestForgotPasswordVerify = async (payload) => {
    try {
        const response = await fetch(forgotPasswordVerifyEndpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
            credentials: 'include',
        });
        return response; 
    } catch (error) {
        console.log(error.message);
        return false;
    }  
};

const refreshEndpoint = endpoint + '/refresh';
export const requestRefresh = async () => {
    try {
        const response = await fetch(refreshEndpoint, {
            method: 'GET',
            credentials: 'include',
        });
        return response;
    } catch (error) {
        console.log(error.message);
        return false;
    }
};
const refreshFetch = async (input, init) => {
    try {
        const response = await fetch(input, init);
        if (response.status == StatusCodes.UNAUTHORIZED) {
            const refreshResponse = await requestRefresh();
            if (refreshResponse.status == StatusCodes.UNAUTHORIZED) {
                return refreshResponse;
            }
            const response = await fetch(input, init);
            return response;
        }
        else {
            return response;
        }
    } catch (error) {
        if (error.name === "AbortError") return;
        console.log(error.message);
        return false;
    }
}

const changePasswordEndpoint = endpoint + '/auth/change-password';
export const requestChangePassword = async (payload) => {
    const response = await refreshFetch(changePasswordEndpoint, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response; 
};

const changeEmailEndpoint = endpoint + '/profile/email';
export const requestChangeEmail = async (payload) => {
    const response = await refreshFetch(changeEmailEndpoint, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response; 
};

const changeEmailVerifyEndpoint = endpoint + '/profile/verify-email';
export const requestChangeEmailVerify = async (payload) => {
    const response = await refreshFetch(changeEmailVerifyEndpoint, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response; 
};

const changePhoneEndpoint = endpoint + '/profile/phone';
export const requestChangePhone = async (payload) => {
    const response = await refreshFetch(changePhoneEndpoint, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response; 
};

const changePhoneVerifyEndpoint = endpoint + '/profile/verify-phone';
export const requestChangePhoneVerify = async (payload) => {
    const response = await refreshFetch(changePhoneVerifyEndpoint, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response; 
};

const logoutEndpoint = endpoint + '/log-out';
export const requestLogout = async () => {
    const response = await refreshFetch(logoutEndpoint, {
        method: 'POST',
        credentials: 'include',
    });
    return response;
};

// profile

const profileEndpoint = endpoint + '/profile';
export const requestProfile = async () => {
    const response = await refreshFetch(profileEndpoint, {
        method: 'GET',
        credentials: 'include',
    });
    return response;
}

// subscription

const tokenEndpoint = endpoint + '/token';
export const requestToken = async () => {
    const response = await refreshFetch(tokenEndpoint, {
        method: 'GET',
        credentials: 'include',
    });
    return response;
}

const balanceEndpoint = endpoint + '/balance';
export const requestBalance = async () => {
    const response = await refreshFetch(balanceEndpoint, {
        method: 'GET',
        credentials: 'include',
    });
  return response;
}

const subscriptionEndpoint = endpoint + '/subscription';
export const requestSubscription = async (signal) => {
    const response = await refreshFetch(subscriptionEndpoint, {
        method: 'GET',
        credentials: 'include',
        signal
    }, );
    return response;
};
export const requestCreateSubscription = async (payload) => {
    const response = await refreshFetch(subscriptionEndpoint, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response;   
};
export const requestChangeSubscription = async (payload) => {
    const response = await refreshFetch(subscriptionEndpoint, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response;   
};
export const requestCancelSubscription = async () => {
    const response = await refreshFetch(subscriptionEndpoint, {
        method: 'DELETE',
        credentials: 'include',
    });
    return response;   
};

const paymentMethodEndpoint = endpoint + '/subscription/payment-method';
export const requestPaymentMethod = async () => {
    const response = await refreshFetch(paymentMethodEndpoint, {
        method: 'GET',
        credentials: 'include',
    });
    return response;
};

const subscriptionPreviewEndpoint = endpoint + '/subscription/preview';
export const requestSubscriptionPreview = async (payload) => {
    const response = await refreshFetch(subscriptionPreviewEndpoint, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });
    return response;
};

const subscriptionResumeEndpoint = endpoint + '/subscription/resume';
export const requestSubscriptionResume = async () => {
    const response = await refreshFetch(subscriptionResumeEndpoint, {
        method: 'POST',
        credentials: 'include',
    });
    return response;
};

const billingEndpoint = endpoint + '/billing';
export const requestBilling = async () => {
    const response = await refreshFetch(billingEndpoint, {
        method: 'GET',
        credentials: 'include',
    });
    return response;
};
