import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PillButton from "./PillButton";
import * as PopupStyles from "./style/PopupStyles";

export default function Popup(props) {
    // Use a state variable to track the current page
    const [currentPage, setCurrentPage] = useState(0);

    const [pending, setPending] = useState(props.pending);

    const pages = props.pages.filter((page, index) => {
        return !(index === 1 && !pending);
    });

    const [totalPages, setTotalPages] = useState(props.pages.length);

    // Function to handle clicking the "back" button
    const handleBack = () => {
        // Decrement the current page if it is greater than 0
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle clicking the "continue" button
    const handleContinue = () => {
        if (props.unpaid) {
            window.location.href = "./unpaid-subscription";
            return;
        }
        setPending(props.pending);
        // Increment the current page if it is less than the total number of pages
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <PopupStyles.PopupBGDiv isOpen={true}>
            <PopupStyles.PopupBoxDiv>
                {pages[currentPage]}

                {/* Render the "back" button if the current page is greater than 0 */}
                {currentPage > 0 && (
                    <PillButton value={"Back"} handleClick={handleBack}></PillButton>
                )}

                {/* Render the "continue" button if the current page is less than the total number of pages */}
                {currentPage < pages.length - 1 &&
                    (pending && currentPage == 1 ? (
                        <PillButton value={"Yes"} handleClick={handleContinue}></PillButton>
                    ) : (
                        <PillButton value={"Continue"} handleClick={handleContinue}></PillButton>
                    ))}

                {/* Render the "confirm" button if the current page is the last page */}
                {currentPage == pages.length - 1 && (
                    <PillButton value={"Continue"} handleClick={props.handleSubmit}></PillButton>
                )}

                {/* Render the "close" button if the current page is 0 */}
                {currentPage == 0 && (
                    <PillButton value={"Close"} handleClick={props.handleClose}></PillButton>
                )}

            </PopupStyles.PopupBoxDiv>
        </PopupStyles.PopupBGDiv>
    );
}

Popup.defaultProps = {
    pending: false,
    withButtons: true,
};
