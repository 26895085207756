import React from "react";
import styled, { keyframes } from "styled-components";

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`;

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.svg`
    animation: ${rotate} 2s linear infinite;
    width: 50px;
    height: 50px;
    margin: auto;
    padding: 50px;
`;

const CircularStroke = styled.circle`
    stroke: grey;
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
`;

export default function LoadingWheel() {
    return (
        <Spinner class="spinner" viewBox="0 0 50 50">
            <CircularStroke
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
            ></CircularStroke>
        </Spinner>
    );
}
