import React, {useState, useEffect} from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { requestBilling } from '../../../components/account/RequestHandlers';

const StyledContentDiv = styled.div`
	height: 100%;
	width: min(500px, 90vw);
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
    padding: 2em;
    margin: auto;
    a {
		text-decoration: none;
		color: grey;
		:hover {
			color: silver;
		}
	}
`;

export default function PurchasedPlan() {
    const history = useNavigate();
    const [remainingTime, setRemainingTime] = useState(10);

    const isUserManuallyEntered = document.referrer === '';

    const handleBillingSubmit = async () => {
		const response = await requestBilling();
		if (response.ok) {
			const result = await response.json();
			window.open(result.url, "_blank");
		} else {
			return false;
		};
	};

    useEffect(() => {
        const redirectTimer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
        }, 1000); 

        if (remainingTime === 0) {
            clearInterval(redirectTimer);
            window.location.href = "/account";
        }

        return () => clearInterval(redirectTimer);
    }, [history, remainingTime]);
    return(
        <>
            {isUserManuallyEntered ? <Navigate to={"/account"}/> :           
                <StyledContentDiv>
                    <p>
                        <b>Thanks for purchasing an avatar plan!</b>
                    </p>
                    <p>
                        Make sure to enter your token in the SDK to begin using your plan. Feel free to message us with any questions or feedback about the SDK or purchase process.
                    </p>
                    <p><a onClick={handleBillingSubmit}>Click here to view my invoice</a></p>
                    <p><a href="/account">Click here to go back to account or you will be redirected in {remainingTime} seconds...</a></p>
                </StyledContentDiv>
            }
        </>
    )
}