import styled from "styled-components";
import React, { useRef, useState } from 'react'
import { device } from '../models/device';
import { BackgroundSwiper } from "../components/BackgroundSwiper"

import topHero from '../images/about_us/top_hero_original.jpg';
import topHeroMobile from '../images/about_us/top_hero_mobile.jpg';
import inputImage from '../images/about_us/input.png'

import { ChevronFooter } from '../components/CevronFooter'
import ScrollToTop from "../components/ScrollToTop";

import * as Hero from "../components/Hero";

const StyledHeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    height: 72px;
    margin-top: 16px;
    color: ${props => props.theme.text};
    font-size: 2.0em;
    font-weight: bold;
    @media ${device.mobile} {
        align-items: center;
        //font-size: 4.0em;
        text-align: center;
    }
`;

const LargeP = styled.p`
    //font-size: 1.5em;
    font-weight: normal;
    @media ${device.mobile} {
        text-align: center;
    }
`

const LargeA = styled.a`
    //font-size: 1.25em;
    font-weight: bold;
    @media ${device.mobile} {
        text-align: center;
    }
`

const StyledP = styled.p`
    display: flex;
    flex-direction: column;
    font-size: 2.0em;
    font-weight: bold;
    z-index: 10;
    pointer-events: all;
    justify-self: center;
    max-width: 50%;
    align-items: flex-start;
    > a {
        text-align: center;
        display: inline-block;
        text-decoration: underline;
        :hover {
            text-decoration: none;
        }
    }

    @media ${device.mobile} {
        filter: invert(1);
        text-align: center;
        justify-content: center;
        align-items: center;
    }
`;

export const StyledInputDiv = styled.div`
    position: absolute;
    top: ${props => props.top ? props.top : "0"};
    left: ${props => props.left ? props.left : "0"};
    display: flex;
    justify-content: center;
    width: 10%;
    @media ${device.mobile} {
        display: none;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 0;
        width: 0;
        height: 0;
    }
`

export const StyledMailP = styled.p`
    pointer-events: all;
    max-width: 50%;
    color: ${props => props.theme.text};
    margin: 10px 0;
    filter: ${props => props.invert ? "invert(1)" : ""};
    @media ${device.mobile} {
        filter: none;
        color: ${props => props.theme.primary};
        max-width: 90%;
        a {
            color: ${props => props.theme.primary};
        }
    }
    a {
        font-weight: bold;
        text-decoration: none;
        :hover{
            text-decoration: none;
        }
    }
`

function ContactUs( {dimensions} ) {
    return (
        <Hero.StyleSlideHidder>
            <Hero.StyledSlideImage className="slider-bg" imgRatio={3.14601018676} src={dimensions.width > parseInt(device.mobile.split(':')[1], 10) ? topHero : topHeroMobile}>
            </Hero.StyledSlideImage>
            <Hero.StyledContainerDiv>
                <Hero.StyledInputDiv data-swiper-parallax={400} top={'10%'} imgRatio={3.14601018676} imgOffsetScalar={0.145}>
                    <Hero.StyledInputPreviewImage imgRatio={3.14601018676} imgWidthScalar={0.065} src={inputImage}/>
                </Hero.StyledInputDiv>
                <Hero.StyledContentDivTop>
                    <Hero.StyledTaglineH1 invert>Want to contact us?</Hero.StyledTaglineH1>
                    <StyledMailP invert>Support: <a href={'mailto:support@avatarneo.com'}>support@avatarneo.com</a></StyledMailP>
                    <StyledMailP invert>General Info: <a href={'mailto:info@pinscreen.com'}>info@pinscreen.com</a></StyledMailP>
                    <StyledMailP invert>Join Us: <a href={'mailto:jobs@pinscreen.com'}>jobs@pinscreen.com</a></StyledMailP>
                    <br />
                    <Hero.StyledDescriptionP invert>
                        <b>Avatar Neo is a product of Pinscreen, Inc. To learn more visit: <a target="_blank" href='https://www.pinscreen.com'>www.pinscreen.com</a></b>
                    </Hero.StyledDescriptionP>
                </Hero.StyledContentDivTop>
            </Hero.StyledContainerDiv>
        </Hero.StyleSlideHidder>
    )
}

export default function AboutUsPinscreen() {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [percentShown, setPercentShow] = useState({
        Buttons1: 0,
        Buttons2: 0,
        Buttons3: 0,
        Chevron: 0,
      });
      React.useEffect(() => {
        function handleResize() {
            //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);
    const [lastScrollTime, setLastScrollTime] = useState(new Date());
    const [isScrolling, setIsScrolling] = useState(false);
    const [isAutoScrolling, setIsAutoScrolling] = useState(false);
    const Buttons1Ref = useRef(), Buttons2Ref = useRef(), Buttons3Ref = useRef(), Panel1Ref = useRef(), Panel2Ref = useRef(), Panel3Ref = useRef();
    const ChevronRef = React.createRef();

    return (
        <Hero.StyledHeroDiv>
            {dimensions.height < 450 + 96 + 75 ? 
            <Hero.RegularScrollDiv>
                <Hero.RegularHeroDiv background={'#191415'}>
                    <ContactUs dimensions={dimensions}/>
                </Hero.RegularHeroDiv>
            </Hero.RegularScrollDiv> : 
            <>
                <ScrollToTop/>
                <BackgroundSwiper>
                    <Hero.StyleSlide ref={Panel1Ref} background={'#191415'}>
                        <ContactUs dimensions={dimensions}/>
                    </Hero.StyleSlide>
                </BackgroundSwiper>
            </>}
            <ChevronFooter animatePercent={percentShown.Chevron} ref={ChevronRef}></ChevronFooter>
        </Hero.StyledHeroDiv>
    )
}