import React, { useContext } from "react";
import { Plans } from "./Plans";
import { Link } from "react-router-dom";
import { AccountContext } from "../../../App";

import * as AccountStyles from "../../style/AccountStyles";

export default function PlanSelection(props) {
  const { prices } = useContext(AccountContext);

  return (
    <AccountStyles.ContentDiv width="600px">
      <AccountStyles.InfoSectionHeaderDiv>
        <h1>{props.title}</h1>
        {props.handleCancel && (
          <Link to={""} onClick={props.handleCancel}>
            Cancel Subscription
          </Link>
        )}
      </AccountStyles.InfoSectionHeaderDiv>
      {Plans.tiers.map((tier) => (
        <AccountStyles.PlanRowDiv>
          {tier == '25+10' ? 
          <AccountStyles.PlanRowLabel><strike>25 avatars/month</strike><br /><b>35 avatars/month</b></AccountStyles.PlanRowLabel> : 
          <AccountStyles.PlanRowLabel>{tier} avatars/month</AccountStyles.PlanRowLabel>}
          <AccountStyles.PlanRowDiv>
          {Plans.periods.map((period) => {
            return (
              <AccountStyles.BoxButton
                onClick={() => props.onPlanSelected(tier, period)}
                selected={
                  props.selectedPlan.tier === tier &&
                  props.selectedPlan.period === period
                    ? true
                    : false
                }
                disabled={
                  props.currentPlan && props.renewalPlan && 
                  props.currentPlan.tier === tier &&
                  props.currentPlan.period === period &&
                  props.currentPlan.tier === props.renewalPlan.tier &&
                  props.currentPlan.period === props.renewalPlan.period
                    ? true
                    : false
                }
              >
                <AccountStyles.BoxButtonText>
                  {prices[`${tier}`][`${period}`].display.amount_due}
                </AccountStyles.BoxButtonText>
                {props.currentPlan && props.currentPlan.tier === tier &&
                props.currentPlan.period === period ? (
                  <AccountStyles.BoxButtonNote>*current plan</AccountStyles.BoxButtonNote>
                ) : props.renewalPlan && props.renewalPlan.tier === tier &&
                  props.renewalPlan.period === period ? (
                  <AccountStyles.BoxButtonNote>*renewal plan</AccountStyles.BoxButtonNote>
                ) : (
                  <></>
                )}
              </AccountStyles.BoxButton>
            );
          })}
          </AccountStyles.PlanRowDiv>
        </AccountStyles.PlanRowDiv>
      ))}
    </AccountStyles.ContentDiv>
  );
}
