import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Header from "../../../components/Header";
import { Navigate } from "react-router-dom";
import {
    requestCreateSubscription,
} from "../../../components/account/RequestHandlers";
import Popup from "../../../components/PopupMultiPages";
import PlanSelection from "../../../components/account/subscription/PlanSelection";
import PillButton from "../../../components/PillButton";
import { AccountContext } from "../../../App";
import LoadingWheel from "../../../components/account/LoadingWheel";

const StyledContentDiv = styled.div`
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
`;

const StyledText = styled.p`
    text-align: left;
    font-size: 1rem;
    word-break: break-word;
    line-height: 1.4rem;
    color: ${(props) => props.theme.text};
    align-self: start;
`;

const StyledTitle = styled.p`
    text-align: center;
    font-size: 1.25rem;
    word-break: break-word;
    margin: 0;
    font-weight: bold;
    color: ${(props) => props.theme.text};
`;

export default function AccountCreatePlan(props) {  
    const { authenticated, subscription, prices } = useContext(AccountContext);
    
    const [selectedPlan, setSelectedPlan] = useState({
        tier: null,
        period: null,
    });

    const handleCreateSubscriptionSubmit = async () => {
        if (selectedPlan.tier && selectedPlan.period) {
            const payload = selectedPlan;
            const response = await requestCreateSubscription(payload);
            if (response.ok) {
                const result = await response.json();
                if (result?.url) {
                    window.location.href = result.url;
                }
            }
        } else return false;
    };

    const [popup, setPopup] = useState({ open: false });
    const closePopup = () => {
        setPopup({ open: false });
    };

    const handleContinue = () => {
        setPopup({ open: true });
    }

    const [loading, setLoading] = useState(true);
	useEffect(() => {
		const abortController = new AbortController();
		const loadAccountInfo = async () => {
			if (!authenticated) await props.onAccountRequest(abortController.signal);
			setLoading(false);	
		}
		loadAccountInfo();
		return () => abortController.abort();
	}, []);

    return (
        <>
        {loading ? (
            <LoadingWheel />
        ) : (
                <>
                    {authenticated && (!subscription) ? (    
                        <>{popup.open && (
                            <Popup
                                pages={[
                                    <>
                                        <StyledTitle>Confirmation</StyledTitle>
                                        <StyledText>
                                            <b>New Plan:</b>
                                            <br />
                                            {selectedPlan.tier} avatars {selectedPlan.period} plan
                                        </StyledText>
                                        <StyledText>
                                            You will receive an invoice on 
                                            {" "}{new Date(prices[`${selectedPlan.tier}`][`${selectedPlan.period}`].display.bills_on).toLocaleDateString()} 
                                            {" "}for
                                            {" "}{prices[`${selectedPlan.tier}`][`${selectedPlan.period}`].display.amount_due}.
                                        </StyledText>
                                    </>,
                                ]}
                                handleClose={closePopup}
                                handleSubmit={handleCreateSubscriptionSubmit}
                            />
                        )}
                        <Header title="Account" />
                        <StyledContentDiv>
                            <PlanSelection
                                title={"Create Subscription Plan"}
                                selectedPlan={selectedPlan}
                                onPlanSelected={(tier, period) =>
                                    setSelectedPlan({ tier: tier, period: period })
                                }
                            />
                            <PillButton
                                dark
                                value={"Continue"}
                                handleClick={handleContinue}
                            ></PillButton>
                        </StyledContentDiv></>
                    ) : (
                        <Navigate to="/account" replace={true} />
                    )}
                </>
            )}
        </>
    );
}
