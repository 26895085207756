import React from "react";
import * as FormStyles from "../../style/FormStyles";
import LoadingWheel from "../LoadingWheel";

export function LoadingForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
            <   LoadingWheel />
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}
