import NavbarList from './NavbarList'
import PillButton from './PillButton'
import styled, { withTheme } from "styled-components";
import React, {Fragment, useState} from 'react'
import { Link } from 'react-router-dom';
import {useSpring, animated} from 'react-spring'
import useMeasure from 'react-use-measure'

import { device } from '../models/device';
import logo from '../images/landing/navigation/pinscreen_logo.png';
import menu from '../images/landing/navigation/menu.svg';
import { useRef } from 'react';

const StyledInnerList = styled.ul`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    @media ${device.mobile} {
        width: 100%;
        flex-direction: column;
    }
    li {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }
    a {
        text-align: center;
        width: 100%;
    }
`;

const StyledOuterList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 37.5px 0 37.5px;
    height: 100%;
    @media ${device.mobile} {
        padding: 0 37.5px 0 0;
        justify-content: space-between;
    }
`;

const StyledLogoListItem = styled(animated.li)`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    a {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        h2 {
            white-space: nowrap;
        }
    }
`;

const StyledVertialBox = styled.div`
    height: 1.25em;
    display: flex;
    flex-direction: column;
    line-height: 0;
    justify-content: center;
`

const StyledCenterNavListItem = styled.li`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    gap: 10%;
    @media ${device.mobile} {
        left: 0;
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
    }
`;

const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 192px;
    height: auto;
    text-align: center;
`;

const StyledLogoImage = styled.img`
    display: block;
    width: 64px;
    height: 64px;
    margin-right: 24px;

    @media ${device.mobile} {
        width: 36px;
        height: 36px;
        padding: 2px;
        padding-bottom: 6px;
        margin-right: 0px;
    }
`;

const StyledMenuButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    text-align: center;
    border-radius: 0;
    img {
        display: block;
        width: 24px;
        height: 24px;
        padding: 12px;
    }
`;

const StyledNavbarDiv = styled.div`
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    @media ${device.mobile} {
        width: 100%;
    }
    position: relative;
    z-index: 20;
    top: 0;
    background: ${props => props.theme.secondary};
    min-height: 75px;
    height: auto;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column; 
    width: 100%;
    flex-grow: 1;
`;

const StyledListButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 192px;
    @media ${device.mobile} {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        justify-content: center;
        align-items: center;
    }
    text-align: center;
    color: black;
    text-decoration: none;
    font-size: 1.1em;
    background: ${props => props.theme.buttonVariant};
    :hover {
        background: ${props => props.theme.buttonHoverVarient};
    }
`;

const VerticalP = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
`;

const HorizontalP = styled.div`
    display: flex;
    flex-direction: row;
    height: auto;
    margin: 0;
    margin-block: 0;
`;

const NoMarginP = styled.p`
    margin: 0;
    margin-block: 0;
`;

const NoMarginH2 = styled.h2`
    margin: 0;
    margin-block: 0;
`;

function mobileContent(animatedMenuStyle, mobileMenuRef, mobileMenuOpen, setMobileMenuOpen, animatedRef, setTitleIsHovered, titleHoverAnimStyle) {
    return (
       <Fragment>
            <StyledLogoListItem>

                <StyledMenuButton ref={mobileMenuRef} onClick={() => {setMobileMenuOpen(!mobileMenuOpen); console.log(mobileMenuOpen)}}>
                    <img src={menu}/>
                </StyledMenuButton>
                <animated.div style={animatedMenuStyle}>
                <StyledCenterNavListItem ref={animatedRef}>
                    <StyledInnerList>
                        <li>
                            <StyledDiv>
                                <Link to={'/account'}>
                                    <StyledListButton onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>Account</StyledListButton>
                                </Link>
                            </StyledDiv>
                        </li>
                        <li>
                            <StyledDiv>
                                <Link to={'/creator-app-download'}>
                                        <StyledListButton onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>Creator App</StyledListButton>
                                </Link>
                            </StyledDiv>
                        </li>
                        <li>
                            <StyledDiv>
                                <Link to={'/sdk-download'}>
                                        <StyledListButton onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>SDK</StyledListButton>
                                </Link>
                            </StyledDiv>
                        </li>
                        <li>
                            <StyledDiv>
                                <Link to={'/resources-documentation'}>
                                        <StyledListButton onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>Documentation</StyledListButton>
                                </Link>
                            </StyledDiv>
                        </li>
                        <li>
                            <StyledDiv>
                                <Link to={'/about-us-pinscreen'}>
                                        <StyledListButton onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>About Us</StyledListButton>
                                </Link>
                            </StyledDiv>
                        </li>
                    </StyledInnerList>
                </StyledCenterNavListItem>
                </animated.div>
            </StyledLogoListItem>
            <StyledLogoListItem style={titleHoverAnimStyle} onMouseEnter={ () => {setTitleIsHovered(true);}} onMouseLeave={ () => {setTitleIsHovered(false);}}>
                <Link to="/">
                    <VerticalP>
                        <h2>AVATAR NEO</h2>
                        <HorizontalP>
                            <NoMarginH2>*</NoMarginH2>
                            <NoMarginP>beta</NoMarginP>
                        </HorizontalP>
                    </VerticalP>
                    <StyledLogoImage src={logo}/>
                </Link>
            </StyledLogoListItem>
       </Fragment>
    )
   }

function desktopContent(setTitleIsHovered, titleHoverAnimStyle) {
 return (
    <Fragment>
        <StyledCenterNavListItem>
            <StyledLogoListItem style={titleHoverAnimStyle} onMouseEnter={ () => {setTitleIsHovered(true);}} onMouseLeave={ () => {setTitleIsHovered(false);}}>
                <Link to="/">
                    <StyledLogoImage src={logo}/>
                    <h2>AVATAR NEO *</h2>
                    <p>beta</p>
                </Link>
            </StyledLogoListItem>
            <StyledInnerList>
                <li>
                    <Link to={'/creator-app-download'}>
                            <StyledListButton>Creator App</StyledListButton>
                    </Link>
                </li>
                <li>
                    <Link to={'/sdk-download'}>
                            <StyledListButton>SDK</StyledListButton>
                    </Link>
                </li>
                <li>
                    <Link to={'/resources-documentation'}>
                            <StyledListButton>Documentation</StyledListButton>
                    </Link>
                </li>
                <li>
                    <Link to={'/about-us-pinscreen'}>
                            <StyledListButton>About Us</StyledListButton>
                    </Link>
                </li>
                <li>
                    <Link to='/account'>
                        <StyledListButton><b>Account</b></StyledListButton>
                    </Link>
                </li>
            </StyledInnerList>
        </StyledCenterNavListItem>
    </Fragment>

 )
}

export default function Navbar() {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
    const [mobileMenuRef, mobileMenuBounds] = useMeasure()
    const animatedRef = useRef()
    
    React.useEffect(() => {
        function handleResize() {
            //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const mobileMenuAnimatedStyle = useSpring({
        config: { duration: 180 },
        width:  '100%',
        height: (mobileMenuOpen && animatedRef ? animatedRef.current.offsetHeight : 0) + "px",
        overflow: 'hidden',
        top: mobileMenuBounds.bottom,
        left: 0,
        boxShadow: '20px 0px 20px -25px black',
        background: 'white',
        position: 'absolute',
        })

        const [titleIsHovered, setTitleIsHovered] = React.useState(false);
        const titleHoverAnimStyle = useSpring({
            /* add once we decide on the style here */
            config: { mass: 1, tension: 500, friction: 80 }
            });

    return(
        <StyledNavbarDiv>
                <StyledOuterList>
                    { dimensions.width > parseInt(device.mobile.split(':')[1], 10) ? desktopContent(setTitleIsHovered, titleHoverAnimStyle) : mobileContent(mobileMenuAnimatedStyle, mobileMenuRef, mobileMenuOpen, setMobileMenuOpen, animatedRef, setTitleIsHovered, titleHoverAnimStyle) }
                </StyledOuterList>
        </StyledNavbarDiv>
    )
}