import React, {useState} from 'react'
import styled from "styled-components";
import {useSpring, animated} from 'react-spring'
import useMeasure from 'react-use-measure'
import {BrowserRouter as Router, Link} from 'react-router-dom';

import { device } from '../models/device';

const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 128px;
    @media ${device.mobile} {
        width: 100%;
        border-radius: 0;
    }
    text-align: center;
`;

const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: 0 0 6px 0;
    @media ${device.mobile} {
        background: lightgrey;
        padding: 0;
    }

    li {
        margin: 6px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        a {
            text-align: center;
            width: 100%;
        }

        @media ${device.mobile} {
            margin: 0px;
        }
    }
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledNav = styled.nav`
    display: flex;
    width: 100%;
`;

const StyledListButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    text-align: center;
    padding: 6px 0 6px 0;
    @media ${device.mobile} {
        border-radius: 0;
        background: lightgrey;
        :hover {
            background: grey;
        }
    }
`;

export default function NavbarList({title, contents}) {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [showList, setShowList] = useState(false);
    const [listRef, listBounds] = useMeasure()
    const [buttonRef, buttonBounds] = useMeasure()

    React.useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const listItems = contents.map((item) =>
        <li>
            <Link to={item[1]}>
                <StyledListButton>{item[0]}</StyledListButton>
            </Link>
        </li>
    );

    function enter () {
        setShowList(true);
    }

    function leave () {
        setShowList(false);
    }

    //width:  `calc(${buttonBounds.width} + 24px)`

    const listContentAnimatedStyle = useSpring({
        config: { duration: 180 },
        height: showList ? listBounds.height : 0,
        opacity: showList ? 1 : 0,
        width:  buttonBounds.width,
        overflow: 'hidden',
        position: dimensions.width > 1024 ? 'absolute' : 'static',
        top: buttonBounds.bottom,
        })


    return(
        <StyledNav>
            <StyledDiv  onMouseLeave={leave}>
                <StyledButton onMouseEnter={enter} ref={buttonRef}>{title}</StyledButton>
                <animated.div style={listContentAnimatedStyle}>
                    <StyledList ref={listRef}>
                        {listItems}
                    </StyledList>
                </animated.div>
            </StyledDiv>
        </StyledNav>
    )
}