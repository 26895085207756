import React from "react";
import * as FormStyles from "../../style/FormStyles";
import logo from "../../../images/landing/navigation/avatarneo.png";
import LoadingWheel from "../LoadingWheel";

export function SignInForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Sign in</FormStyles.FormTitleText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput name="email" autocomplete="email" required />
                            <FormStyles.FormLabel>Email</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput name="password" type="password" autocomplete="current-password" required />
                            <FormStyles.FormLabel>Password</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormHelperText>
                            <a href='/account/forgot-password'>Forgot password?</a>
                        </FormStyles.FormHelperText>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Sign in</FormStyles.FormButton>
                        <FormStyles.FormHelperText>
                            Don't have an account? <a href="/account/sign-up">Sign up</a>
                        </FormStyles.FormHelperText>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}

export function SignIn2FAForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Two-Factor Authentication</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            Enter the one-time code sent to your email address. The code will expire in about 10 minutes.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput type="number" name="code" autocomplete="one-time-code" required />
                            <FormStyles.FormLabel>Verification Code</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Verify</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}
