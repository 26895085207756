import React, { useState } from "react";
import * as FormStyles from "../../style/FormStyles";
import logo from "../../../images/landing/navigation/avatarneo.png";
import LoadingWheel from "../LoadingWheel";

export function ChangePhoneForm(props) {
    const [value, setValue] = useState("");
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Update Phone Number</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            Please enter your new phone number.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormPhoneInput
                                name="phone"
                                autocomplete="tel"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="US"
                                value={value}
                                onChange={setValue}
                                required
                            />
                            <FormStyles.FormPhoneLabel>
                                Phone Number
                            </FormStyles.FormPhoneLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Next</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}

export function ChangePhoneVerifyForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Verify your phone number</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            We've sent a 6-digit verification code to your phone. The code will expire in about 10 minutes.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput type="number" name="code" autocomplete="one-time-code" required />
                            <FormStyles.FormLabel>Verification Code</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormHelperText>
                            <a href='/account/change-phone'>Back</a>
                        </FormStyles.FormHelperText>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Verify</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}

export function ChangePhoneSuccessForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Success!</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            Your phone number has been changed.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Back to Account</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}
