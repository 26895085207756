import { React, useContext, useState, useEffect } from "react";
import { SignUpForm, VerifyPhoneForm } from "../../../components/account/authentication/SignUpForm";
import { SignIn2FAForm } from "../../../components/account/authentication/SignInForm";
import { getCountryCallingCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
    requestSignUp,
    requestSignIn,
    requestVerifyPhone,
    requestVerifyEmail,
} from "../../../components/account/RequestHandlers";
import { LoadingForm } from "../../../components/account/authentication/LoadingForm";

export default function SignIn(props) {
    const [verify, setVerify] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [signInPayload, setSignInPayload] = useState(null);

    const handleSignUpSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            name: "".concat(data.get("firstname"), " ", data.get("lastname")),
            email: data.get("email"),
            password: data.get("password"),
            phone: data.get("phone"),
        };
        setSignInPayload({email: payload.email, password: payload.password});
        // remove format characters from phone, keep only '+' and digits
        payload.phone = payload.phone?.replace(/[^\d\+]+/g, "");
        const response = await requestSignUp(payload);
        if (response) {
            if (response.ok) {
                setVerify(true);
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleVerifyPhoneSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            code: data.get("code"),
        };
        const response = await requestVerifyPhone(payload);
        if (response) {
            if (response.ok) {
                setErrorMsg(null);
                setSubmitting(false);
                handleAutoSignInSubmit();
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleVerifyEmailSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            code: data.get("code"),
        };
        const response = await requestVerifyEmail(payload);
        if (response) {
            if (response.ok) {
                setErrorMsg(null);
                window.location.href = "./";
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleAutoSignInSubmit = async () => {
        setVerifyEmail(true);
        const response = await requestSignIn(signInPayload);
        if (response) {
            if (response.ok) {
                setVerify(true);
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                console.log(result);
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const [loading, setLoading] = useState(true);
	useEffect(() => {
		const abortController = new AbortController();
		const checkAuthStatus = async () => {
            const isAuth = await props.onAuthStatusRequest();
            if (isAuth) window.location.href = "./";
            else setLoading(false);	
		}
		checkAuthStatus();
		return () => abortController.abort();
	}, []);

    return (
        <>
            {loading ? (
                <LoadingForm />
            ) : (
                <>
                    {verify ? (
                        <>
                            {verifyEmail ? (                      
                                <SignIn2FAForm
                                    handleSubmit={handleVerifyEmailSubmit}
                                    errorMsg={errorMsg}
                                    loading={submitting}
                                />
                            ) : (
                                <VerifyPhoneForm
                                    handleSubmit={handleVerifyPhoneSubmit}
                                    errorMsg={errorMsg}
                                    loading={submitting}
                                />
                            )}
                        </>
                    ) : (
                        <SignUpForm
                            handleSubmit={handleSignUpSubmit}
                            errorMsg={errorMsg}
                            loading={submitting}
                        />
                    )}
                </>
            )}
        </>
    );
}
