import styled from "styled-components";
import React from 'react'

export const StyledGraphicsImg = styled.img`
    height: auto;
    width: auto;
    /* min-width: ${props => props.imgWidth};
    width: ${props => props.imgWidth}; */
    box-shadow: 4px 4px 10px -3px ${props => props.theme.panelDark};
    border-radius: 1px;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${props => props.theme.panelDark};
`

export default function InputPreviewImage(props) {
    const { className } = props;
    return(
        <StyledGraphicsImg imgWidth={props.imgWidth} className={className} src={props.src}/>
    )
}