import styled from "styled-components";

export const PopupBGDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #000000cc;
    z-index: 999;
    display: ${(props) => props.isOpen ? props.isOpen==true ? 'flex' : 'none' : 'none'};
`;

export const PopupBoxDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: auto;
    min-width: min(100vw, 225px);
    max-width: 400px;
    background: ${(props) => props.theme.primary};
    border-radius: 30px;
    padding: 50px;
    gap: 1rem;
`;

export const PopupTitle = styled.p`
    text-align: start;
    font-size: 1.25rem;
    word-break: break-word;
    margin: 0;
    font-weight: bold;
    color: ${(props) => props.theme.text};
    align-self: start;
    width: 100%;
    padding-bottom: 0.3em;
    border-bottom: 1px solid lightgrey; 
`;

export const PopupText = styled.p`
    position: relative;
    text-align: left;
    font-size: 1rem;
    word-break: break-word;
    line-height: 1.4rem;
    color: ${(props) => props.theme.text};
    align-self: start;
    margin: 0;
    margin-bottom: 1rem;
    a {
        text-decoration: none;
        color: ${(props) => props.theme.accent};
        &:hover {
            color: ${(props) => props.theme.accentVariant};
        }
    }
`;

export const PopupTextOverlap = styled(PopupText)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.primary};
`;

export const PopupCode = styled(PopupText)`
    font-family: monospace;
`;