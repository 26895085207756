import { React, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ForgotPasswordRequestForm, ForgotPasswordVerifyForm } from "../../../components/account/authentication/ForgotPasswordForm";
import {
    requestForgotPassword,
    requestForgotPasswordVerify
} from "../../../components/account/RequestHandlers";
import { LoadingForm } from "../../../components/account/authentication/LoadingForm";

export default function ForgotPassword(props) {
    const [requested, setRequested] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const handleForgotPasswordRequestSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            email: data.get("email"),
        };
        const response = await requestForgotPassword(payload);
        if (response) {
            if (response.ok) {
                setRequested(true);
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                console.log(result);
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleForgotPasswordVerifySubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            token: data.get("token"),
        };
        const response = await requestForgotPasswordVerify(payload);
        if (response) {
            if (response.ok) {
                setErrorMsg(null);
                window.location.href = "/account/change-password";
            } else {
                const result = await response.json();
                console.log(result);
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const [loading, setLoading] = useState(true);
	useEffect(() => {
		const abortController = new AbortController();
		const checkAuthStatus = async () => {
            const isAuth = await props.onAuthStatusRequest(abortController.signal);
            if (isAuth) window.location.href = "./";
            else setLoading(false);	
		}
		checkAuthStatus();
		return () => abortController.abort();
	}, []);

    return (
        <>
            {loading ? (
                <LoadingForm />
            ) : (
                <>
                    {requested ? (
                        <ForgotPasswordVerifyForm
                            handleSubmit={handleForgotPasswordVerifySubmit}
                            errorMsg={errorMsg}
                            loading={submitting}
                        />
                    ) : (
                        <ForgotPasswordRequestForm
                            handleSubmit={handleForgotPasswordRequestSubmit}
                            errorMsg={errorMsg}
                            loading={submitting}
                        />
                    )}
                </>
            )}
        </>
    );
}
