import React from 'react'
import styled from "styled-components"
import { Link } from 'react-router-dom';

import { ReactComponent as Unreal } from '../images/generic/icons/unreal.svg'
import { ReactComponent as Unity } from '../images/generic/icons/unity.svg'

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(var(--app-height) - 128px - 96px - 72px - 16px);
    overflow: hidden;
`;

const StyledCenterDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
`;

const StyledButtonsDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    margin-top: 24px;
`;

const StyledImageButton = styled.button`
    height: auto;
    width: 100%;
    &:hover{
        background: ${props => props.theme.text};
        *{
            fill: white;
        }
    }
`;

const StyledUnrealLogo = styled(Unreal)`
    fill: black;
`

const StyledUnityLogo = styled(Unity)`
    fill: black;
`

const StyledLogoLink = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 45%;
    margin-right: 24px;
`

export default function ResourcesHome() {   
    return (
        <StyledDiv>
            <StyledCenterDiv>
                <p>
                    Complete Resources for Learning how to Use Avatar Neo SDK. Here is a quick start <a href="https://www.youtube.com/watch?v=Mc3DpMaKjtU">video</a> tutorial if you want to get started with the Creator App. <br/><br/>
                    For additional questions contact <a href="mailto:support@avatarneo.com">support@avatarneo.com</a>
                </p>
                <StyledButtonsDiv>
                    <StyledLogoLink to={'/resources-documentation/unity'}>
                        <StyledImageButton>
                            <StyledUnityLogo/>
                        </StyledImageButton>
                    </StyledLogoLink>
                    <StyledLogoLink to={'/resources-documentation/unreal'}>
                        <StyledImageButton>
                            <StyledUnrealLogo/>
                        </StyledImageButton>
                    </StyledLogoLink>
                </StyledButtonsDiv>
            </StyledCenterDiv>
        </StyledDiv>
    );
}
