import styled, { css } from "styled-components";
import React, {useState, useEffect} from 'react'
import { device } from '../models/device';
import { useSpring, animated } from 'react-spring';

export const StyledButtonInputBase = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: auto;
    text-align: center;
    margin: 0;
    padding: 12px;
    border: none;
    border-radius: 64px;
    border-width: 4px;
    font-size: 1em;
    @media ${device.mobile} {
        //width: 128px;
    }
`;

export const StyledButton = styled.input`
    ${StyledButtonInputBase}
    background: ${props => props.light ? props.invert ? props.theme.button : "white" : props.invert ? "white" : props.theme.button };
    color: ${props => props.light ? props.invert ? "white" : props.theme.button : props.invert ? props.theme.button : "white" };
    outline-color: ${props => props.light ? props.invert ? "white" : props.theme.button : props.invert ? props.theme.button : "white" };
    outline-style: solid;
    outline-width: 0px;
    :hover {
        background: ${props => props.light ? props.invert ? "white" : props.theme.button : props.invert ? props.theme.button : "white" };
        color: ${props => props.light ? props.invert ? props.theme.button : "white" : props.invert ? "white" : props.theme.button };
        outline-color: ${props => props.light ? props.invert ? props.theme.button : "white" : props.invert ? "white" : props.theme.button };
        outline-width: 2px;
    }
`;

export const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: auto;
`;

function buttonContent(props, setIsHovered) {
    return(
        <StyledButton type="button" light={props.light} invert={props.invert} outlineHover={props.outlineHover} value={props.value} onChange={props.handleChange} onClick={props.handleClick} onMouseEnter={ () => {setIsHovered(true);}} onMouseLeave={ () => {setIsHovered(false);}}>
            {props.children}
        </StyledButton>
    );
}

export default function PillButton(props) {
    const [isHovered, setIsHovered] = React.useState(false);
    const hoverAnimStyle = useSpring({
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        config: { mass: 1, tension: 500, friction: 80 }
        });
    useEffect(() => {
        // button animation on hover using react spring
        
      }, []);
    return(
        <div className={props.pillClass}>
            <animated.div style={hoverAnimStyle}>
                <StyledLabel>
                    {buttonContent(props, setIsHovered)}
                </StyledLabel>
            </animated.div>
        </div>
    )
}