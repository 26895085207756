import React from "react";
import * as FormStyles from "../../style/FormStyles";
import logo from "../../../images/landing/navigation/avatarneo.png";
import LoadingWheel from "../LoadingWheel";

export function ChangePasswordForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Set Your New Password</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            Please use at least 8 characters.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput name="password" type="password" autocomplete="new-password" required />
                            <FormStyles.FormLabel>Password</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Confirm</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}

export function ChangePasswordSuccessForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Success!</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            Your password has been changed.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Back to Account</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}
