import React from 'react'
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import styled from "styled-components";

const StyledReactMarkdown = styled(ReactMarkdown)`
    a {
        text-decoration: underline;
    }

    a:hover, a:focus, a:active {
        color: ${props => props.theme.button};
        text-decoration: none;
    }
`;

export default function MarkdownDocumentation(props) {
    return(
        <div className='md'>
            <StyledReactMarkdown rehypePlugins={[rehypeRaw]} 
            components={{
                h1: props => {
                    return <h1 name={props.name}>{props.children}</h1>
                },
                a: props => {
                    return <a name={props.name} href={props.href}>{props.children}</a>
                }
    }}>{props.value}</StyledReactMarkdown>
        </div>
    )
}
