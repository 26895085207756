import styled from "styled-components";
import React, {forwardRef} from 'react'
import chevron from '../images/generic/down_chevron.png';

const StyledExpanderDiv = styled.div`
    z-index: 20;
    bottom: 0;
    left: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${ props => ((props.animatePercent / 100.0) * 96.0) + `px` || `96px`};
    width: 100vw;
    box-sizing: border-box;
    background: white;
    overflow: hidden;
    transition: height 0.25s;
`;

const StyledFooterDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

`;

const StyledFooterChevron = styled.img`
    width: 48px;
    height: 24px;
    margin-bottom: 12px;
    opacity: ${ props => (props.animatePercent / 100.0) || 1.0};
    transition: opacity 0.16s;
`;

export const ChevronFooter = React.forwardRef((props, ref) => (
    <StyledExpanderDiv animatePercent={props.animatePercent} ref={ref}>
        <StyledFooterDiv animatePercent={props.animatePercent}>
            <StyledFooterChevron animatePercent={props.animatePercent} src={chevron} onClick={() => {window.scrollTo({top: window.scrollY + window.innerHeight - 96, left: 0, behavior: 'smooth'});}}/>
        </StyledFooterDiv>
    </StyledExpanderDiv>
  ));

