import React from 'react'
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

import { Mousewheel, Pagination, Parallax, Scrollbar, Navigation, FreeMode } from "swiper";

const CarouselSwiper = styled(Swiper)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    width: 100vw;
    height: calc(var(--app-height) - 75px - 96px);
    --swiper-pagination-color: #000;
    --swiper-theme-color: #000;
    --swiper-pagination-bullet-inactive-color: #999;
    --swiper-pagination-bullet-inactive-opacity: 1.0;
`
export const BackgroundSwiper = React.forwardRef((props, ref) => (
    <CarouselSwiper direction={"vertical"} parallax={true} mousewheel={{sensitivity:1, releaseOnEdges:false}} scrollbar={true} freeMode={{sticky:true, momentum:true}} modules={[Mousewheel, Parallax, Scrollbar, FreeMode, Navigation]} loop={false} ref={ref} navigation={{nextEl: ".next"}}>
        {props.children}
    </CarouselSwiper>
  ));