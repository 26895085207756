import styled from "styled-components";
import React, {useState, useRef, useEffect} from 'react'
import Header from '../components/Header'
import { device } from '../models/device';
import PillButton from "../components/PillButton";
import { Link } from 'react-router-dom';

import { BackgroundSwiper } from "../components/BackgroundSwiper"
import { SwiperSlide } from "swiper/react";

import { ReactComponent as Unreal } from '../images/generic/icons/unreal.svg'
import { ReactComponent as Unity } from '../images/generic/icons/unity.svg'
import sdkScreen from '../images/sdk_download/sdk_graphic.webp'

import DownloadPopup from "../components/Popup";
import { PopupText } from "../components/style/PopupStyles";
import { isMobile } from 'react-device-detect';
import UnityDownload from '../downloads/sdk/unity_avatarneo_sdk_0.1.0.zip';
import UnrealDownload from '../downloads/sdk/unreal_avatarneo_sdk_0.1.2.zip';

const StyledLink = styled(Link)`
    text-decoration: underline;
    :hover {
        text-decoration: none;
    }
`

const StyledOuterContainerDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    //justify-content: center;
    align-items: center;
`

const StyledContainerDiv = styled.div`
    width: 100%;
    max-width: 60%;
    display: flex;
    height: 100%;
    justify-content: center;
    gap: 10%;
    text-align: left;
    @media ${device.mobile} {
        flex-direction: column;
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 90vw;
        max-width: 90%;
        height: auto;
        gap: 0;
    }
`

const StyledDescriptionDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 70%;
    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 24px;
    }
`

const StyledDownloadDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    height: auto;
    @media ${device.mobile} {
        width: 100%;
        align-items: center;
    }
`

const StyledGraphicsDiv = styled.img`
    display: flex;
    height: auto;
    width: 100%;
    @media ${device.mobile} {
        width: min(100%, 500px);
    }
`

const StyledButtonsDiv = styled.div`
    display: flex;
    gap: 1rem;
    margin: 10px 0;
`

const StyledDividerDiv = styled.div`
    height: 2px;
    background: black;
    max-width: 192px;
    width: 100%;
    margin: 16px 0 16px 0;
`

const StyledPageDiv = styled(SwiperSlide)`
    max-width: 100vw;
    height: calc(var(--app-height) - 256px - 96px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${props => props.background};
`

const StyledPageDivBot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-bottom: 75px;

    @media ${device.mobile} {
        /* probably need to fix further as isn't exactly one page */
        height: 100%;
        width: 100%;
    }
`

const StyleSlideHidder = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
`

const RegularScrollDiv = styled.div`
    display: flex;
    width: 100vw;
    height: auto;
    flex-direction: column;
    justify-content: center;
`

const RegularContentDiv = styled.div`
    position: relative;
    width: 100vw;
    height: auto;
    min-height: 1000px;
    display: flex;
    justify-content: center;
    background: ${props => props.background};
`

const StylePageContent = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
`

const StylePageContentTall = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
`

const StyledHeader = styled(Header)`
    margin-top: 75px;
`

const StyledEncapsulateingDiv = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    height: auto;
    @media ${device.mobile} {
        height: calc(200vh - 256px - 96px);
        width: 100%;
    }
`

const StyledHoriztonalDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 24px 0 0 0;
    @media ${device.mobile} {
        margin: 0;
        justify-content: center;
        *{
            text-align: center;
        }
    }
`

const StyledVerticalDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    line-height: 1.2em;
    @media ${device.mobile} {
        flex-direction: row;
        gap: .5em;
        justify-content: center;
        align-items: center;
        *{
            text-align: center;
        }
        p {
            font-size: .75em;
            margin: 5px;
            white-space: nowrap;
        }
    }
`

const StyledLogoDiv = styled.div`
    width: 47px;
    height: 47px;
    @media ${device.mobile} {
        width: 0;
        height: 0;
    }
`

const StyledP = styled.p`
    //margin-block-start: 0;
    @media ${device.mobile} {
        width: min(100%, 500px);
    }
`

const StyledUnrealLogo = styled(Unreal)`
    fill: black;
`

const StyledUnityLogo = styled(Unity)`
    fill: black;
`

function TopContent(props) {
    return (
        <StyleSlideHidder>
            <StyledHeader title='Avatar Neo SDK' />
            <StyledOuterContainerDiv>
            <StyledContainerDiv>
                <StyledDescriptionDiv>
                    <StyledP>
                        Avatar Neo SDK allows developers to easily incorporate 3D avatar generation from photos
                        into 3D apps, games, and AR/VR experiences. The application communicates with our
                        servers and generates a 3D avatar from the uploaded photo. Start by choosing a platform
                        such as Unreal or Unity, and create an account or login to get your activation token.
                    </StyledP>
                    <StyledGraphicsDiv src={sdkScreen}></StyledGraphicsDiv>
                </StyledDescriptionDiv>
                <StyledDownloadDiv>
                    {props.dimensions.height < 1000 ? <a href={'#download'}><PillButton dark value={"Get Started"} pillClass="next"/></a> : <a><PillButton dark value={"Get Started"} pillClass="next"/></a>}
                    <br />
                    <StyledHoriztonalDiv>
                        <StyledVerticalDiv>
                            <StyledLogoDiv>
                                <StyledUnrealLogo/>
                            </StyledLogoDiv>
                            <p><b>Unreal Engine Support:</b></p>
                            <p>
                                Unreal Engine 4.xx or higher
                            </p>
                        </StyledVerticalDiv>
                    </StyledHoriztonalDiv>
                    <StyledHoriztonalDiv>
                        <StyledVerticalDiv>
                            <StyledLogoDiv>
                                <StyledUnityLogo/>
                            </StyledLogoDiv>
                            <p><b>Unity Support:</b></p>
                            <p>
                                Unity 20xx.x.xxf1 LTS or later
                            </p>
                        </StyledVerticalDiv>
                    </StyledHoriztonalDiv>
                </StyledDownloadDiv> 
            </StyledContainerDiv>
            </StyledOuterContainerDiv>
        </StyleSlideHidder>
    )
}

function BotContent(props) {
    return (
        <StyleSlideHidder>
            <a name="download"/>
            <Header title='Download' />
            {/* <StylePageContentTall> */}
            <p><b>Step 1: </b>Download SDK for your desired platform</p>
            <StyledButtonsDiv>
                <PillButton dark value={"Unreal"} handleClick={
                    () => {
                        props.setPopupOpener(!props.popupOpener);
                        const link = document.createElement('a');
                        link.href = UnrealDownload;
                        link.setAttribute('download', 'unreal_avatarneo_sdk_0.1.2.zip'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // cleanup element and remove ObjectURL
                        document.body.removeChild(link);
                    }
                    }/>
                <PillButton dark value={"Unity"} handleClick={
                    () => {
                        props.setPopupOpener(!props.popupOpener);
                        const link = document.createElement('a');
                        link.href = UnityDownload;
                        link.setAttribute('download', 'unity_avatarneo_sdk_0.1.0.zip'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // cleanup element and remove ObjectURL
                        document.body.removeChild(link);
                    }
                    }/>
            </StyledButtonsDiv>
            <StyledDividerDiv/>
            <p><b>Step 2: </b>Create an Avatar Neo account or login to get your activation token</p>
            <StyledButtonsDiv>
                <Link to={'/account/sign-up'}>
                    <PillButton dark value={"Sign Up"}></PillButton>
                </Link>
                <Link to={'/account/sign-in'}>
                    <PillButton dark value={"Login"} ></PillButton>
                </Link>
            </StyledButtonsDiv>
            <StyledDividerDiv/>
            <p><b>Step 3: </b>Follow the installing and start developing</p>
            <StyledButtonsDiv>
                <Link to={'/resources-documentation/unity#installation'}>
                    <PillButton dark value={"Installation"}></PillButton>
                </Link>
                <Link to={'/resources-documentation'}>
                <PillButton dark value={"Documentation"} ></PillButton>
                </Link>
            </StyledButtonsDiv>
            {/* </StylePageContentTall> */}
        </StyleSlideHidder>
    )
}

export default function SDKDownload(props) {
    const [popupOpener, setPopupOpener] = useState(true);
    const bottomPageRef = useRef(), slideRef = useRef(), ButtonRef = useRef();
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    useEffect(() => {
    function handleResize() {
        //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        });
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
        window.removeEventListener('resize', handleResize)    }
    }, []);

    return(
        <>
            {dimensions.height < 1000 ? 
                <RegularScrollDiv>
                    <RegularContentDiv><TopContent dimensions={dimensions}/></RegularContentDiv>
                    <RegularContentDiv><BotContent popupOpener={popupOpener} setPopupOpener={setPopupOpener}/></RegularContentDiv>
                </RegularScrollDiv> :
                <BackgroundSwiper ref={slideRef} nextEl={ButtonRef.current}>
                    <StyledPageDiv>
                        <TopContent dimensions={dimensions}/>
                    </StyledPageDiv>
                    <StyledPageDiv ref={bottomPageRef}>
                        <BotContent popupOpener={popupOpener} setPopupOpener={setPopupOpener}/>  
                    </StyledPageDiv>         
                </BackgroundSwiper> 
            }
            <DownloadPopup title={'Download Starting'} popupOpener={popupOpener} >
                { isMobile ? 
                <PopupText>Thanks for downloading the Avatar Neo SDK but it appears you are on a mobile device. Try downloading on desktop to use the SDK!</PopupText> : 
                <PopupText>Thanks for downloading the Avatar Neo SDK! To start using the SDK make an <StyledLink to={'/account'}>account</StyledLink> and get a token.</PopupText>}
            </DownloadPopup>
        </>
    )
}
