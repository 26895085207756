import styled from "styled-components";
import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Header from '../components/Header'
import { device } from '../models/device';
import PillButton from "../components/PillButton";
import DownloadPopup from "../components/Popup";
import { PopupText } from "../components/style/PopupStyles";

import creatorGraphic from '../images/creator_app/creator_graphic.webp';

import { ReactComponent as Apple } from '../images/generic/icons/apple.svg';
import { ReactComponent as Windows } from '../images/generic/icons/windows.svg';

import { isMobile } from 'react-device-detect';

import MacDownload from '../downloads/creator_app/avatarneo_creator_macos_0.1.6.zip';
import WinDownload from '../downloads/creator_app/avatarneo_creator_win64_0.1.6.zip';


const StyledContainerDiv = styled.div`
    width: 100%;
    height: 100%;
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    text-align: left;
    @media ${device.mobile} {
        flex-direction: column;
        align-self: center;
        width: 90vw;
        max-width: 450px;
    }
`

const StyledDescriptionDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    @media ${device.mobile} {
        width: 100%;
    }
`

const StyledDownloadDiv = styled.div`
    width: 30%;
    @media ${device.mobile} {
        width: 100%;
    }
`

const StyledGraphicsDiv = styled.img`
    display: flex;
    height: auto;
    width: 100%;
    align-self: center;
    flex: 1;
`

const StyledHeader = styled(Header)`
    margin-top: 75px;
`

const StyledEncapsulateingDiv = styled.div`
    max-width: 60%;
    //height: calc(var(--app-height) - 75px - 96px);
    @media ${device.mobile} {
        max-width: 90%;
    }
    display: flex;
    flex-direction: column;
    //overflow: hidden;
`

const StyledHoriztonalDiv = styled.div`
    display: flex;
    flex-direction: horizontal;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @media ${device.mobile} {
        margin-top: 48px;
    }
`

const StyledDescriptionP = styled.p`
    text-align: center;
`

const StyledLogoDiv = styled.div`
    width: 47px;
    height: 47px;
    margin: 12px;
`

const StyledLogoWideDiv = styled.div`
    width: 47px;
    height: 47px;
    margin: 12px;
    transform: scale(1.22876) translate(0, -6px);
`

const StyledWindowsLogo = styled(Windows)`
    fill: ${props => props.theme.button};
`

const StyledAppleLogo = styled(Apple)`
    fill: ${props => props.theme.button};
`

const StyledLink = styled(Link)`
    text-decoration: underline;
    :hover {
        text-decoration: none;
    }
`

export default function CreatorAppDownload() {
    const [popupOpener, setPopupOpener] = useState(true);
    const [downloadProgress, setDownloadProgress] = useState(0);

    return(
        <StyledEncapsulateingDiv>
            <StyledHeader title='Avatar Neo Creator App' />
                    <StyledDescriptionP>
                        The easiest way to test our 3D avatar digitization solution is to download our Avatar Neo Creator App. Simply upload a photo to generate your personalized 3D avatar. To use the the creator app you need to first make an <Link to={'/account'}>account</Link>.
                    </StyledDescriptionP>    
            <StyledContainerDiv>
                <StyledDescriptionDiv>
                    <StyledGraphicsDiv src={creatorGraphic}></StyledGraphicsDiv>
                </StyledDescriptionDiv> 
                <StyledDownloadDiv>
                    <StyledHoriztonalDiv>
                        <StyledLogoDiv>
                            <StyledWindowsLogo/>
                        </StyledLogoDiv>
                        <PillButton dark outlineHover value={"Download"} handleClick={
                            () => {
                                setPopupOpener(!popupOpener);
                                const link = document.createElement('a');
                                link.href = WinDownload;
                                link.setAttribute('download', 'avatarneo_creator_win64_0.1.6.zip');
                                document.body.appendChild(link);
                                link.click();
                                // cleanup element and remove ObjectURL
                                document.body.removeChild(link);
                            }
                            }>
                        </PillButton>
                        {/* <PillDownloadButton dark outlineHover link={WinDownload} downloadName={'avatarneo_creator_win64_0.1.3.zip'} handleClick={() => {setPopupOpener(!popupOpener);}}>
                            Download
                        </PillDownloadButton> */}
                    </StyledHoriztonalDiv>
                    <p><b>Recommended:</b></p>
                    <p>
                        Window 10/11 64-bit
                        <br/>
                        Quad-core Intel/AMD, 2.5 GHz or faster
                        8GB RAM
                    </p>
                    <br/>
                    <StyledHoriztonalDiv>
                        <StyledLogoWideDiv>
                            <StyledAppleLogo/>
                        </StyledLogoWideDiv>
                        <PillButton dark outlineHover value={"Download"} handleClick={
                            () => {
                                setPopupOpener(!popupOpener);
                                const link = document.createElement('a');
                                link.href = MacDownload;
                                link.setAttribute('download', 'avatarneo_creator_macos_0.1.6.zip'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                                // cleanup element and remove ObjectURL
                                document.body.removeChild(link);
                            }
                            }>
                        </PillButton>
                        {/* <PillDownloadButton dark outlineHover link={MacDownload} downloadName={'avatarneo_creator_macos_0.1.3.zip'} handleClick={() => {setPopupOpener(!popupOpener);}}>
                            Download
                        </PillDownloadButton> */}
                    </StyledHoriztonalDiv>
                    <p><b>Recommended:</b></p>
                    <p>
                        Big Sur
                        <br/>
                        Quad-core Intel/M1, 2.5 GHz or faster, 8 GB RAM
                    </p>
                </StyledDownloadDiv> 
            </StyledContainerDiv>
            <DownloadPopup title={'Download Starting'} popupOpener={popupOpener} progress={downloadProgress}>
                { isMobile ? 
                <PopupText>Thanks for downloading the Creator App but it appears you are on a mobile device. Try downloading on desktop to use the creator app!</PopupText> : 
                <PopupText>Thanks for downloading the Creator App! To start making avatars make an <StyledLink to={'/account'}>account</StyledLink>, get a token and enter it into the Creator App.</PopupText>}
            </DownloadPopup>
        </StyledEncapsulateingDiv>
    )
}