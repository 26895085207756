import styled from "styled-components";
import React, { useRef, useState } from 'react'
import { device } from '../models/device';
import PillButton from "./PillButton";
import { BackgroundSwiper } from "./BackgroundSwiper"
import { SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';
import topHero from '../images/landing/hero/top_hero_original.jpg';
import topHeroMobile from '../images/landing/hero/top_hero_mobile.jpg';
import midHero from '../images/landing/hero/mid_hero_original.jpg';
import midHeroMobile from '../images/landing/hero/mid_hero_mobile.jpg';
import botHero from '../images/landing/hero/bot_hero_original.jpg';
import botHeroMobile from '../images/landing/hero/bot_hero_mobile.jpg';

import inputImage from '../images/landing/hero/input.png';
import inputImage2 from '../images/landing/hero/input2.png';
import inputImage3 from '../images/landing/hero/input3.png';

import { ChevronFooter } from './CevronFooter'
import InputPreviewImage from "./InputPreviewImage";
import ScrollToTop from "./ScrollToTop";

export const StyledHeroDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background: ${props => props.theme.primary};
`
export const StyledContainerDiv = styled.div`
    pointer-events: none;
    display: flex;
    width: 1500px;
    height: 100%;
    justify-content: center;
    @media ${device.mobile} {
        flex-direction: column;
    }
`

export const StyledContentDivTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: ${props => props.theme.text};
    padding: 0 50px;
    width: 100%;
    min-height: 450px;
    z-index: 8;
    @media ${device.mobile} {
        min-height: 0;
        padding: 5vh 20px;
        margin-top: 20vh;
        width: auto;
        align-items: center;
        text-align: center;
        background: #00000080;
    }
`

export const StyledContentDivHoriz = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: auto;
    pointer-events: all;
    @media ${device.mobile} {
        width: 90%;
        filter: invert(1);
        flex-direction: column;
        justify-content: center;
    }
`

export const StyledContentDivVert = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 10%;
    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
`

export const StyledInputDiv = styled.div`
    --calc-raw-height: calc(var(--app-height) - 96px - 75px);
    --calc-exact-height: calc( max(var(--calc-raw-height), 450px));
    --calc-width: calc( var(--calc-exact-height) * (${props => props.imgRatio}) ); 
    position: absolute;
    top: ${props => props.top ? props.top : "unset"};
    display: flex;
    justify-content: center;
    width: auto;
    z-index: 4;
    left: calc(50vw - ( max( min(var(--calc-raw-height), 650px), 550px) - 700px) + (var(--calc-width) * ${props => props.imgOffsetScalar}));
    @media ${device.tablet} {
        /* 640px = 1/2 device tablet cutoff */
        left: calc(100vw - ( max( min(var(--calc-raw-height), 650px), 550px) - 700px) - (640px - (var(--calc-width) * ${props => props.imgOffsetScalar})));
    }
    @media ${device.mobile} {
        display: none;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 0;
        width: 0;
        height: 0;
    }
`

export const StyledTaglineH1 = styled.h1`
    pointer-events: all;
    color: ${props => props.theme.text};
    margin: 0px;
    filter: ${props => props.invert ? "invert(1)" : ""};
    max-width: 50%;
    @media ${device.mobile} {
        filter: none;
        color: ${props => props.theme.primary};
        max-width: 90%;
    }
`

export const StyledDescriptionP = styled.p`
    pointer-events: all;
    max-width: 50%;
    color: ${props => props.theme.text};
    margin: 10px 0;
    filter: ${props => props.invert ? "invert(1)" : ""};
    @media ${device.mobile} {
        filter: none;
        color: ${props => props.theme.primary};
        max-width: 90%;
        a {
            color: ${props => props.theme.primary};
        }
    }
    a {
        font-weight: bold;
    }
`

export const StyledButtonsDiv = styled.div`
    pointer-events: all;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 50px;
    opacity: ${ props => (props.animatePercent / 100) || `1`};
    transition: opacity 0.5s;
    @media ${device.mobile} {
        margin-top: 12px;
    }
`

export const StyledGraphicsImg = styled.img`
    height: auto;
    width: ${props => props.imgWidth};
    max-width: 100%;
    box-shadow: 4px 4px 10px -3px ${props => props.theme.panelDark};
    border-radius: 10px;
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${props => props.theme.panelDark};
`

export const StyledInputP = styled.p`
    position: absolute;
    bottom: -40px;
    left: 0;
    font-size: 1em;
    font-weight: bold;
    pointer-events: all;
    color: ${props => props.theme.text};
    margin: 10px 0;
    filter: ${props => props.invert ? "invert(1)" : ""};
    @media ${device.mobile} {
        filter: invert(1);
        //font-size: 3em;
    }
`

export const StyledSlideImage = styled.img`
    z-index: 0;
    position: absolute;
    width: auto;
    --calc-raw-height: calc(var(--app-height) - 96px - 75px);
    --calc-exact-height: calc( max(var(--calc-raw-height), 450px));
    --calc-width: calc( var(--calc-exact-height) * (${props => props.imgRatio}) ); 
    height: calc(var(--app-height) - 96px - 75px);
    min-height: 450px;
    right: calc(((50vw + max( min(var(--calc-raw-height), 650px), 550px) - 700px)) - (var(--calc-width) / 2.0)); 
    @media ${device.tablet} {
        right: calc((640px + ( max( min(var(--calc-raw-height), 650px), 550px) - 700px)) - (var(--calc-width) / 2.0)); 
    }
    @media ${device.mobile} {
        right: auto;
    }
`

export const StyleSlide = styled(SwiperSlide)`
    position: relative;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    background: ${props => props.background};
`

export const RegularScrollDiv = styled.div`
    display: flex;
    width: 100vw;
    height: auto;
    flex-direction: column;
    justify-content: center;
`

export const RegularHeroDiv = styled.div`
    position: relative;
    width: 100vw;
    height: auto;
    min-height: 450px;
    display: flex;
    justify-content: center;
    background: ${props => props.background};
`

export const StyleSlideHidder = styled.div`
    width: 100vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
`

export const StyledInputPreviewImage = styled(InputPreviewImage)`
    min-width: calc(( max(var(--app-height) - 96px - 75px, 450px) * ${props => props.imgRatio}) * (${props => props.imgWidthScalar}));
    width: calc(( max(var(--app-height) - 96px - 75px, 450px) * ${props => props.imgRatio}) * (${props => props.imgWidthScalar}));
`

function SlideChange(swiperCore) {
    const {
        activeIndex,
        snapIndex,
        previousIndex,
        realIndex,
    } = swiperCore;
}

function TopHero( {dimensions, percentShown, Buttons1Ref} ) {
    return (
        <StyleSlideHidder>
            <StyledSlideImage data-swiper-parallax={"23%"} className="slider-bg" imgRatio={2.9978632478632} src={dimensions.width > parseInt(device.mobile.split(':')[1], 10) ? topHero : topHeroMobile}/>
            <StyledContainerDiv className="content">
                <StyledInputDiv data-swiper-parallax={400} top={'7.8%'} imgRatio={2.9978632478632} imgOffsetScalar={0.03}>
                    <StyledInputPreviewImage imgRatio={2.9978632478632} imgWidthScalar={0.05} src={inputImage}/>
                </StyledInputDiv>
                <StyledContentDivTop>
                    <StyledTaglineH1>AI-Generated 3D Avatars from a Photo</StyledTaglineH1>
                    <StyledDescriptionP><a target="_blank" href={'https://www.pinscreen.com'}>Pinscreen</a> introduces the easiest and most accessible way to create personalized photoreal avatars for games and virtual worlds. Simply upload a photo and specify height and weight to generate a full body avatar. Our SDK is now available for Unreal and Unity.</StyledDescriptionP>
                    <StyledButtonsDiv animatePercent={percentShown.Buttons1} ref={Buttons1Ref}>
                        <Link to={'/creator-app-download'}>
                        <PillButton light={dimensions.width < parseInt(device.mobile.split(':')[1], 10)} value={"Try the App"}/>
                        </Link>
                        <Link to={'/sdk-download'}>
                        <PillButton light={dimensions.width < parseInt(device.mobile.split(':')[1], 10)} value={"Install SDK"} />
                        </Link>
                        <Link to={'https://youtu.be/SUYcTjre0Vg?si=-uENdyQEnM-70U8_d'} target={'_blank'}>
                        <PillButton light={dimensions.width < parseInt(device.mobile.split(':')[1], 10)} value={"Watch Demo"} />
                        </Link>
                    </StyledButtonsDiv>
                </StyledContentDivTop>
            </StyledContainerDiv>
        </StyleSlideHidder>
    )
}

function MidHero( {dimensions, percentShown, Buttons2Ref} ) {
    return (
        <StyleSlideHidder>
            <StyledSlideImage data-swiper-parallax={"23%"} className="slider-bg" imgRatio={3.28368794326} src={dimensions.width > parseInt(device.mobile.split(':')[1], 10) ? midHero : midHeroMobile}/>
            <StyledContainerDiv>
                <StyledInputDiv data-swiper-parallax={400} top={'7.7%'} imgRatio={3.28368794326} imgOffsetScalar={0.085}>
                    <StyledInputPreviewImage imgRatio={3.28368794326} imgWidthScalar={0.045} src={inputImage2}/>
                </StyledInputDiv>
                <StyledContentDivTop>
                    <StyledTaglineH1>Animation-Ready 3D Avatars</StyledTaglineH1>
                    <StyledDescriptionP>Our generated avatars support standardized facial blendshapes and full body rigs for character animation and motion capture.</StyledDescriptionP>
                    <StyledButtonsDiv animatePercent={percentShown.Buttons2} ref={Buttons2Ref}>
                        <Link to={'/creator-app-download'}>
                        <PillButton light={dimensions.width < parseInt(device.mobile.split(':')[1], 10)} value={"Try the App"}/>
                        </Link>
                        <Link to={'/sdk-download'}>
                        <PillButton light={dimensions.width < parseInt(device.mobile.split(':')[1], 10)} value={"Install SDK"} />
                        </Link>
                        <Link to={'https://youtu.be/SUYcTjre0Vg?si=-uENdyQEnM-70U8_d'} target={'_blank'}>
                        <PillButton light={dimensions.width < parseInt(device.mobile.split(':')[1], 10)} value={"Watch Demo"} />
                        </Link>
                    </StyledButtonsDiv>
                </StyledContentDivTop>
            </StyledContainerDiv>
        </StyleSlideHidder>
    )
}

function BotHero( {dimensions, percentShown, Buttons3Ref} ) {
    return (
        <StyleSlideHidder>
            <StyledSlideImage data-swiper-parallax={"23%"} className="slider-bg" imgRatio={3.20034542314} src={dimensions.width > parseInt(device.mobile.split(':')[1], 10) ? botHero : botHeroMobile} />
            <StyledContainerDiv>
                <StyledInputDiv data-swiper-parallax={400} top={'10%'} imgRatio={3.20034542314} imgOffsetScalar={0.14}>
                    <StyledInputPreviewImage imgRatio={3.20034542314} imgWidthScalar={0.075} src={inputImage3}/>
                </StyledInputDiv>
                <StyledContentDivTop>
                    <StyledTaglineH1 invert>3D Avatar Integration in Virtual Worlds</StyledTaglineH1>
                    <StyledDescriptionP invert>Our SDK makes it easy to integrate 3D avatar generation into Unity and Unreal game and app development. We provide tutorials and examples in our <Link to={'/resources-documentation'}>resources</Link> section.</StyledDescriptionP>
                    <StyledButtonsDiv animatePercent={percentShown.Buttons3} ref={Buttons3Ref}>
                        <Link to={'/creator-app-download'}>
                        <PillButton light value={"Try the App"}/>
                        </Link>
                        <Link to={'/sdk-download'}>
                        <PillButton light value={"Install SDK"} />
                        </Link>
                        <Link to={'https://youtu.be/SUYcTjre0Vg?si=-uENdyQEnM-70U8_d'} target={'_blank'}>
                        <PillButton light value={"Watch Demo"} />
                        </Link>
                    </StyledButtonsDiv>
                </StyledContentDivTop>
            </StyledContainerDiv>
        </StyleSlideHidder>
    )
}


export default function Hero() {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [percentShown, setPercentShow] = useState({
        Buttons1: 0,
        Buttons2: 0,
        Buttons3: 0,
        Chevron: 0,
      });
      React.useEffect(() => {
        function handleResize() {
            //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);
    const [lastScrollTime, setLastScrollTime] = useState(new Date());
    const [isScrolling, setIsScrolling] = useState(false);
    const [isAutoScrolling, setIsAutoScrolling] = useState(false);
    const Buttons1Ref = useRef(), Buttons2Ref = useRef(), Buttons3Ref = useRef(), Panel1Ref = useRef(), Panel2Ref = useRef(), Panel3Ref = useRef();
    const ChevronRef = React.createRef();

    return (
        <StyledHeroDiv>
            {dimensions.height < 450 + 96 + 75 || dimensions.width < 768? 
            <RegularScrollDiv>
                <RegularHeroDiv background={'#634e38'}>
                    <TopHero dimensions={dimensions} percentShown={percentShown} Buttons1Ref={Buttons1Ref}/>
                </RegularHeroDiv>
                <RegularHeroDiv background={'#8fa099'}>
                    <MidHero dimensions={dimensions} percentShown={percentShown} Buttons2Ref={Buttons2Ref}/>
                </RegularHeroDiv>
                <RegularHeroDiv background={'#443F48'}>
                    <BotHero dimensions={dimensions} percentShown={percentShown} Buttons2Ref={Buttons3Ref}/>
                </RegularHeroDiv>
            </RegularScrollDiv> : 
            <>
                <ScrollToTop/>
                <BackgroundSwiper>
                    <StyleSlide ref={Panel1Ref} background={'#634e38'}>
                        <TopHero dimensions={dimensions} percentShown={percentShown} Buttons1Ref={Buttons1Ref}/>
                    </StyleSlide>
                    <StyleSlide ref={Panel2Ref} background={'#8fa099'}>
                        <MidHero dimensions={dimensions} percentShown={percentShown} Buttons2Ref={Buttons2Ref}/>
                    </StyleSlide>
                    <StyleSlide ref={Panel3Ref} background={'#443F48'}>
                        <BotHero dimensions={dimensions} percentShown={percentShown} Buttons2Ref={Buttons3Ref}/>
                    </StyleSlide>
                </BackgroundSwiper>
            </>}
            <ChevronFooter animatePercent={percentShown.Chevron} ref={ChevronRef}></ChevronFooter>
        </StyledHeroDiv>
    )
}