import { React, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ChangePasswordForm, ChangePasswordSuccessForm } from "../../components/account/authentication/ChangePasswordForm";
import {
    requestChangePassword
} from "../../components/account/RequestHandlers";
import { AccountContext } from "../../App";
import { LoadingForm } from "../../components/account/authentication/LoadingForm";

export default function ChangePassword(props) {
    const [changed, setChanged] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            password: data.get("password"),
        };
        const response = await requestChangePassword(payload);
        if (response) {
            if (response.ok) {
                setChanged(true);
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleBackToAccountSubmit = async (e) => {
        e.preventDefault();
        window.location.href = "/account";
    };

    const [loading, setLoading] = useState(true);
    const { authenticated } = useContext(AccountContext);
	useEffect(() => {
		const abortController = new AbortController();
		const checkAuthStatus = async () => {
            if (!authenticated) await props.onAuthStatusRequest(abortController.signal);
            setLoading(false);	
		}
		checkAuthStatus();
		return () => abortController.abort();
	}, []);

    return (
        <>
            {loading ? (
                <LoadingForm />
            ) : (
                <>
                    {authenticated ? (
                        <> 
                            {changed ? (
                                <ChangePasswordSuccessForm
                                    handleSubmit={handleBackToAccountSubmit}
                                    errorMsg={errorMsg}
                                    loading={submitting}
                                />
                            ) : (
                                <ChangePasswordForm
                                    handleSubmit={handleChangePasswordSubmit}
                                    errorMsg={errorMsg}
                                    loading={submitting}
                                />
                            )}
                        </>
                    ) : (
                        <Navigate to="/account/sign-in" replace={true} />
                    )}
                </>
            )}
        </>
    );
}
