import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";

export const FormInputDiv = styled.div`
    position: relative;
    padding: 10px 0px;
`;

export const FormInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.text};
    font-size: 0.9rem;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    [type="number"] {
        -moz-appearance: textfield;
    }
`;

export const FormLabel = styled.label`
    line-height: 30px;
    font-size: 0.9rem;
    background: white;
    position: absolute;
    left: 10px;
    top: 15px;
    padding: 0px 5px;
    color: grey;
    pointer-events: none;
    transition: 0.1s ease all;
    ${FormInput}:focus ~ &, ${FormInput}:valid ~ &, ${FormInput}:-webkit-autofill ~ & {
        top: 5px;
        font-size: 0.75rem;
        line-height: 10px;
    }
`;

export const FormPhoneInput = styled(PhoneInput)`
    input,
    div.PhoneInputCountry {
        box-sizing: border-box;
        height: 40px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid ${(props) => props.theme.text};
        font-size: 0.9rem;
    }
    input {
        width: 80%;
    }
`;

export const FormPhoneLabel = styled(FormLabel)`
    left: 70px;
    top: ${(props) => (props.phoneValue?.length === 0 ? "" : "5px")};
    font-size: ${(props) => (props.phoneValue?.length === 0 ? "" : ".75rem")};
    line-height: ${(props) => (props.phoneValue?.length === 0 ? "" : "10px")};
    ${FormPhoneInput}.phoneinput--focus ~ & {
        top: 5px;
        font-size: 0.75rem;
        line-height: 10px;
    }
`;

export const FormTitleText = styled.p`
    font-size: 1.25rem;
    padding: 5px 0px;
    margin: 10px 0 10px 0;
    justify-self: start;
    font-weight: semibold;
`;

export const FormDescriptionText = styled.p`
    font-size: 0.75rem;
    margin: 0 0 20px 0;
`;

export const FormErrorDiv = styled.div`
    font-size: 0.75rem;
    color: red;
    background: #ffdbdb;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
`;

export const FormHelperText = styled.p`
    font-size: 0.75rem;
    a {
        text-decoration: none;
        color: blue;
    }
`;

export const FormButton = styled.button`
    background: ${(props) => props.theme.button};
    color: ${(props) => props.theme.secondary};
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    :hover {
        background: grey;
    }
`;

export const FormSpacerDiv = styled.div`
    margin: auto;
`;

export const FormLogoLink = styled(Link)`
    img {
        width: 100%;
    }
`;

export const FormRowDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.2rem;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: 30px 80px;
    width: 300px;
    height: auto;
    min-height: 450px;
    background-color: ${(props) => props.theme.secondary};
    border-radius: 10px;
    font-size: 0.5rem;
    margin: auto;
`;

export const FormDiv = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.button};
    overflow: auto;
`;
