import styled from "styled-components";
import React from 'react'
import { device } from "../models/device";
import { Link } from 'react-router-dom';

import IconButton from './IconButton'
import TextButton from "./TextButton";

import {ReactComponent as LinkedinIcon} from '../images/footer/linkedin.svg'
import {ReactComponent as InstagramIcon} from '../images/footer/instagram.svg'
import {ReactComponent as FacebookIcon} from '../images/footer/facebook.svg'
import {ReactComponent as TwitterIcon} from '../images/footer/twitter.svg'
import {ReactComponent as YoutubeIcon} from '../images/footer/youtube.svg'
import {ReactComponent as DiscordIcon} from '../images/footer/discord.svg'


const StyledFooterDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StyledList = styled.ul`
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
    li {
        padding: 0 24px 0 24px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        @media ${device.mobile} {
            font-size: .8em;
            padding: 0 10px;
        }
        a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: auto;
        }
    }
`;

// const StyledTextLink = styled.a`
//     color: ${props => props.theme.subPanel};

//     :hover, :focus, :active {
//         color: ${props => props.theme.text};
//         -webkit-text-stroke: 1px ${props => props.theme.subPanel};
//     }
// `

const StyledSocialsList = styled.ul`
    display: flex;
    justify-content: center;
    li {
        padding: 0 6px 0 6px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        @media ${device.mobile} {
            font-size: .8em;
            padding: 0 10px;
        }
    }
`;

const StyledExpanderDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 96px;
    width: 100%;
    background: ${props => props.theme.panelDark};
`;

export default function Footer() {
    return(
        <StyledExpanderDiv>
            <StyledFooterDiv>
                <StyledList>
                    <li>
                        <a href='https://www.pinscreen.com/'>
                            <TextButton>Pinscreen &copy; 2023</TextButton>
                        </a>
                    </li>
                    <li>
                        <a href='/terms-of-use'>
                            <TextButton>Terms</TextButton>
                        </a>
                    </li>
                    <li>
                        <a href='/privacy-policy'>
                        <TextButton>Privacy</TextButton>
                        </a>
                    </li>
                    <li>
                        <a href='mailto:support@avatarneo.com'>
                        <TextButton>Contact</TextButton>
                        </a>
                    </li>
                    <li>
                        <a href='https://www.pinscreen.com/jobs'>
                        <TextButton>Careers</TextButton>
                        </a>
                    </li>
                </StyledList>
                <StyledSocialsList>
                    <li>
                        <Link to={'https://www.linkedin.com/company/pinscreen'}>
                            <IconButton light>
                                <LinkedinIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.instagram.com/pinscreeninc/'}>
                            <IconButton light>
                                <InstagramIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.youtube.com/@pinscreenchannel'}>
                            <IconButton light>
                                <YoutubeIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://discord.gg/9epHwKBj'}>
                            <IconButton light>
                                <DiscordIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.facebook.com/pinscreeninc/'}>
                            <IconButton light>
                                <FacebookIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://twitter.com/PinscreenInc'}>
                            <IconButton light>
                                <TwitterIcon/>
                            </IconButton>
                        </Link>
                    </li>
                </StyledSocialsList>
            </StyledFooterDiv>
        </StyledExpanderDiv>
    )
}