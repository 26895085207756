import { React, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ChangeEmailForm, ChangeEmailVerifyForm, ChangeEmailSuccessForm } from "../../components/account/authentication/ChangeEmailForm";
import {
    requestChangeEmail,
    requestChangeEmailVerify
} from "../../components/account/RequestHandlers";
import { AccountContext } from "../../App";
import { LoadingForm } from "../../components/account/authentication/LoadingForm";

export default function ChangeEmail(props) {
    const [verify, setVerify] = useState(false);
    const [changed, setChanged] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState("");

    const handleChangeEmailSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            email: data.get("email"),
        };
        setEmail(payload.email);
        const response = await requestChangeEmail(payload);
        if (response) {
            if (response.ok) {
                setVerify(true);
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleChangeEmailVerifySubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            email: `${email}`,
            code: data.get("code"),
        };
        const response = await requestChangeEmailVerify(payload);
        if (response) {
            if (response.ok) {
                setChanged(true)
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleBackToAccountSubmit = async (e) => {
        e.preventDefault();
        window.location.href = "/account";
    };

    const [loading, setLoading] = useState(true);
    const { authenticated } = useContext(AccountContext);
	useEffect(() => {
		const abortController = new AbortController();
		const checkAuthStatus = async () => {
            if (!authenticated) await props.onAuthStatusRequest(abortController.signal);
            setLoading(false);	
		}
		checkAuthStatus();
		return () => abortController.abort();
	}, []);

    return (
        <>
            {loading ? (
                <LoadingForm />
            ) : (
                <>
                    {authenticated ? (
                        <> 
                            {changed ? (
                                <ChangeEmailSuccessForm
                                    handleSubmit={handleBackToAccountSubmit}
                                    errorMsg={errorMsg}
                                    loading={submitting}
                                />
                            ) : (
                                <>
                                    {verify ? (
                                        <ChangeEmailVerifyForm
                                            handleSubmit={handleChangeEmailVerifySubmit}
                                            errorMsg={errorMsg}
                                            loading={submitting}
                                        />
                                    ) : (
                                        <ChangeEmailForm
                                            handleSubmit={handleChangeEmailSubmit}
                                            errorMsg={errorMsg}
                                            loading={submitting}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Navigate to="/account/sign-in" replace={true} />
                    )}
                </>
            )}
        </>
    );
}
