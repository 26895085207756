import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Header from "../../../components/Header";
import { Link, Navigate } from "react-router-dom";
import {
    requestBilling,
    requestCancelSubscription,
    requestChangeSubscription,
    requestSubscriptionPreview,
} from "../../../components/account/RequestHandlers";
import Popup from "../../../components/PopupMultiPages";
import PlanSelection from "../../../components/account/subscription/PlanSelection";
import PillButton from "../../../components/PillButton";
import { BoxButton } from "../../../components/style/AccountStyles";
import { AccountContext } from "../../../App";
import LoadingWheel from "../../../components/account/LoadingWheel";

const StyledContentDiv = styled.div`
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
`;

const StyledTitle = styled.p`
    text-align: start;
    font-size: 1.25rem;
    word-break: break-word;
    margin: 0;
    font-weight: bold;
    color: ${(props) => props.theme.text};
    align-self: start;
    width: 100%;
    padding-bottom: 0.3em;
    border-bottom: 1px solid lightgrey; 
`;

const StyledText = styled.p`
    text-align: left;
    font-size: 1rem;
    word-break: break-word;
    line-height: 1.4rem;
    color: ${(props) => props.theme.text};
    align-self: start;
    margin: 0;
    margin-bottom: 1rem;
    a {
        text-decoration: none;
        color: ${(props) => props.theme.accent};
        &:hover {
            color: ${(props) => props.theme.accentVariant};
        }
    }
`;

const StyledNote = styled.p`
    text-align: left;
    font-size: .7rem;
    word-break: break-word;
    line-height: 1.4rem;
    color: ${(props) => props.theme.text};
    align-self: center;
    opacity: 0.4;
    margin-top: -1rem;
    gap: 0;
`;

export default function AccountChangePlan(props) {
    const { successful, setSuccessful, authenticated, subscription, paymentMethod, prices } = useContext(AccountContext);

    const currentPlan = {
        tier: subscription ? subscription.invoiced.name.split(" ")[0] : null,
        period: subscription ? subscription.invoiced.name.split(" ")[2] : null,
    }
    const renewalPlan = {
        tier: subscription ? subscription.upcoming.name.split(" ")[0] : null,
        period: subscription ? subscription.upcoming.name.split(" ")[2] : null,
    }

    const [selectedPlan, setSelectedPlan] = useState({
        tier: null,
        period: null,
    });
    const [pending, setPending] = useState(false);
    const [unpaid, setUnpaid] = useState(false);
    const [allowChangeNow, setAllowChangeNow] = useState(true);
    const [previewChangeNow, setPreviewChangeNow] = useState(null);

    const handleChangeSubscriptionSubmit = async () => {
        if (selectedPlan.tier && selectedPlan.period) {
            if (pending && popup.upgrade_now) {
                const payload = {
                    tier: currentPlan.tier,
                    period: currentPlan.period,
                    upgrade_now: false,
                };
                const response = await requestChangeSubscription(payload);
                if (!response.ok) return false;
            }
            const payload = {
                tier: selectedPlan.tier,
                period: selectedPlan.period,
                upgrade_now: popup.upgrade_now,
            };
            const response = await requestChangeSubscription(payload);
            if (response.ok) {
                const result = await response.json();
                if (result?.url) {
                    //window.open(result.url, "_blank");
                }
                window.location.href = "/account/purchased-plan";
            }
        } else return false;
    };

    const handleCancelSubscriptionSubmit = async () => {
        const response = await requestCancelSubscription();
        if (response.ok) {
            window.location.href = "./";
        } else return false;
    };

    const [popup, setPopup] = useState({ open: false, upgrade_now: false });
    const closePopup = () => {
        setPopup({ open: false, upgrade_now: false });
    };

    const handlePreviewChangeNowSubmit = async () => {
        setAllowChangeNow(true);
        if (selectedPlan.tier && selectedPlan.period) {
            const previewPayload = { tier: selectedPlan.tier, period: selectedPlan.period, upgrade_now: true};
            const previewResponse = await requestSubscriptionPreview(previewPayload);
            const previewResult = await previewResponse.json();
            console.log(previewResult);
            if (previewResult.message === "Not an upgrade") {
                setAllowChangeNow(false);
            }
            if (previewResult.message === "Pending subscription change") {
                setPending(true);
            }   
            if (previewResult.message === "Unpaid subscription") {
                setUnpaid(true);
            }          
            setPopup({ open: true, upgrade_now: false });
            setPreviewChangeNow(previewResult);
        }
    };

    const handleBillingSubmit = async () => {
		const response = await requestBilling();
		if (response.ok) {
			const result = await response.json();
			window.location.href = result.url;
		} else {
			setSuccessful(false);
			return false;
		};
	};

    const [loading, setLoading] = useState(true);
	useEffect(() => {
		const abortController = new AbortController();
		const loadAccountInfo = async () => {
			if (!authenticated) await props.onAccountRequest(abortController.signal);
			setLoading(false);	
		}
		loadAccountInfo();
		return () => abortController.abort();
	}, []);

    return (
        <>
            {loading ? (
                <LoadingWheel />
            ) : (
                <>
                    {authenticated && subscription ? (
                        <>{popup.open && (
                            <Popup
                                pages={[
                                    <>
                                        <StyledTitle>Confirmation</StyledTitle>
                                        <StyledText>
                                            <b>Selected New Plan:</b>
                                            <br />
                                            {selectedPlan.tier} avatars {selectedPlan.period} plan
                                        </StyledText>
                                        <BoxButton
                                            onClick={(e) => setPopup({ open: true, upgrade_now: false })}
                                            selected={!popup.upgrade_now}
                                        >
                                            Set as next plan
                                        </BoxButton>
                                        {allowChangeNow ? (
                                            <BoxButton
                                                onClick={(e) => setPopup({ open: true, upgrade_now: true })}
                                                selected={popup.upgrade_now}
                                            >
                                                Change now
                                            </BoxButton>
                                        ) : (
                                            <>
                                                <BoxButton
                                                    onClick={(e) => setPopup({ open: true, upgrade_now: true })}
                                                    selected={popup.upgrade_now}
                                                    disabled
                                                >
                                                    Change now
                                                </BoxButton>
                                                <StyledNote>*not available for a downgrade</StyledNote>
                                            </>
                                        )}
                                    </>,
                                    <>
                                        <StyledTitle>Confirmation</StyledTitle>
                                        <StyledText>
                                            You've previously requested to change your plan to the
                                            {" "}<b>{subscription.upcoming.name}</b> which will start on
                                            {" "}<b>{new Date(subscription.upcoming.bills_on * 1000).toLocaleDateString()}</b>.
                                            <br />
                                            <br />
                                            Are you sure that you want to update now to the{" "}
                                            <b>
                                                {selectedPlan.tier} avatars {selectedPlan.period} plan
                                            </b>{" "}
                                            instead?
                                        </StyledText>
                                    </>,
                                    <>
                                        <StyledTitle>Confirmation</StyledTitle>
                                        {popup.upgrade_now ? (
                                            <>
                                                <StyledText>
                                                    <b>New Plan (update now) :</b>
                                                    <br />
                                                    {selectedPlan.tier} avatars {selectedPlan.period} plan
                                                </StyledText>
                                                <StyledText>
                                                    You will be charged a {previewChangeNow?.display?.amount_due} difference between your
                                                    new plan and your current {subscription.invoiced.name}.
                                                </StyledText>
                                                <StyledText>
                                                    <b>{paymentMethod}</b>
                                                    <br />
                                                    <Link to={""} onClick={handleBillingSubmit}>
                                                        Change
                                                    </Link>         
                                                </StyledText>
                                            </>
                                        ) : (
                                            <>
                                                <StyledText>
                                                    <b>Update Next Plan :</b>
                                                    <br />
                                                    {selectedPlan.tier} avatars {selectedPlan.period} plan
                                                </StyledText>
                                                <StyledText>
                                                    You will receive an invoice on 
                                                    {" "}{new Date(prices[`${selectedPlan.tier}`][`${selectedPlan.period}`].display.bills_on).toLocaleDateString()} 
                                                    {" "}for
                                                    {" "}{prices[`${selectedPlan.tier}`][`${selectedPlan.period}`].display.amount_due}.
                                                </StyledText>
                                            </>
                                        )}
                                    </>,
                                ]}
                                pending={pending && popup.upgrade_now}
                                unpaid={unpaid && popup.upgrade_now}
                                handleClose={closePopup}
                                handleSubmit={handleChangeSubscriptionSubmit}
                            />
                        )}
                        <Header title="Account" />
                        <StyledContentDiv>
                            <PlanSelection
                                title={"Change Subscription Plan"}
                                handleCancel={handleCancelSubscriptionSubmit}
                                selectedPlan={selectedPlan}
                                currentPlan={currentPlan}
                                renewalPlan={renewalPlan}
                                onPlanSelected={(tier, period) =>
                                    setSelectedPlan({ tier: tier, period: period })
                                }
                            />
                            <PillButton
                                dark
                                value={"Continue"}
                                handleClick={handlePreviewChangeNowSubmit}
                            ></PillButton>
                        </StyledContentDiv></>
                    ) : (
                        <Navigate to="/account/sign-in" replace={true} />
                    )}
                </>
            )}
        </>
    );
}
