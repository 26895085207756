import { createGlobalStyle } from 'styled-components';
import { device } from './models/device';
 
const GlobalStyle = createGlobalStyle`


    * {
        font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        background: ${props => props.theme.primary};
        font-family: 'Helvetica', 'Arial', sans-serif;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        > li {
            margin: 0;
            padding: 0;
        }
    }

    button {
        margin: 0;
        padding: 12px;
        background: ${props => props.theme.secondary};
        border: none;
        border-radius: 64px;
        font-size: 1.5em;

        :hover {
            background: ${props => props.theme.secondaryVariant};
        }
    }

    h1 {
        margin: 0;
        font-size: 2em;
        color: ${props => props.theme.text};
        @media ${device.mobile} {
            font-size: 1.5em;
        }
    }

    h2 {
        margin: 0;
        font-size: 1.25em;
        color: ${props => props.theme.text};
        @media ${device.mobile} {
            font-size: 1em;
        }
    }

    p {
        font-size: 1.25em;
        line-height: 1.3em;
        a {
            text-decoration: underline;
            opacity: 0.9;
        }
        a:hover {
            text-decoration: underline;
            opacity: 0.5;
        }
        @media ${device.mobile} {
            font-size: 1em;
        }
        strike {
            opacity: 0.4;
        }
    }

    a {
        text-decoration: none;
        color: ${props => props.theme.text};
    }

    a:hover, a:focus, a:active {
        text-decoration: none;
    }

    .md {
        padding: 20px;      
    }

    .md h1 {
        font-size: 1.75em;
        padding-bottom: 0.3em;
        border-bottom: 1px solid ${props => props.theme.panel};      
    }

    .md h2 {
        font-size: 1.5em;
        padding-bottom: 0.3em;
        border-bottom: 1px solid ${props => props.theme.secondaryVariant};      
    }

    .md h3 {
        font-size: 1.25em;
    }

    .md h4 {
        font-size: 1.12em;
    }

    .md h5 {
        font-size: 1em;
    }

    .md p {
        font-size: 1em;    
        line-height: 1.5
    }

    .md code {
        padding: 2px 4px;
        font-size: 90%;
        background-color: ${props => props.theme.panel};
        border-radius: 4px;
    }

    .md pre {
        margin-bottom: 16px;
        font-size: 15px;
        line-height: 1.6em;
        overflow-x: auto;
        border-radius: 2px;
        background-color: ${props => props.theme.panel};
        border: 1px solid ${props => props.theme.panelVariant};
        padding: 8px 12px;
        margin: 0 0 8px;
    }

    .md pre code {
        white-space: pre;
        word-wrap: normal;
        overflow-wrap: normal;
        background-color: ${props => props.theme.panel};
        > a {
            color: ${props => props.theme.accent};
            font-family: inherit;
        }
    }

    .md ul, .md ol {
        padding: 0;
        margin: 0 0 16px;
        list-style-type: disc;
    }

    .md li {
        margin-left: 28px;
        padding-left: 0;
        line-height: 1.75em;
    }


`;
 
export default GlobalStyle;