import React, { useState } from "react";
import * as FormStyles from "../../style/FormStyles";
import logo from "../../../images/landing/navigation/avatarneo.png";
import LoadingWheel from "../LoadingWheel";

export function SignUpForm(props) {
    const [value, setValue] = useState("");
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Get Started</FormStyles.FormTitleText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormRowDiv>
                            <FormStyles.FormInputDiv>
                                <FormStyles.FormInput name="firstname" autocomplete="given-name" required />
                                <FormStyles.FormLabel>First Name</FormStyles.FormLabel>
                            </FormStyles.FormInputDiv>
                            <FormStyles.FormInputDiv>
                                <FormStyles.FormInput name="lastname" autocomplete="family-name" required />
                                <FormStyles.FormLabel>Last Name</FormStyles.FormLabel>
                            </FormStyles.FormInputDiv>
                        </FormStyles.FormRowDiv>
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput name="email" autocomplete="email" required />
                            <FormStyles.FormLabel>Email</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput name="password" type="password" autocomplete="new-password" required />
                            <FormStyles.FormLabel>Password</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormPhoneInput
                                name="phone"
                                autocomplete="tel"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="US"
                                value={value}
                                onChange={setValue}
                                required
                            />
                            <FormStyles.FormPhoneLabel>
                                Phone Number
                            </FormStyles.FormPhoneLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormRowDiv>
                            <input type="checkbox" required/>
                            <FormStyles.FormHelperText>
                                I agree to the <a href='/terms-of-use'>Terms of Use</a> and <a href='/privacy-policy'>Privacy Policy</a>.
                            </FormStyles.FormHelperText>
                        </FormStyles.FormRowDiv>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Sign up</FormStyles.FormButton>
                        <FormStyles.FormHelperText>
                            Already have an account? <a href="/account/sign-in">Sign in</a>
                        </FormStyles.FormHelperText>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}

export function VerifyPhoneForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Verify your phone number</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            We've sent a 6-digit verification code to your phone. The code will expire in about 10 minutes.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput type="number" name="code" autocomplete="one-time-code" required />
                            <FormStyles.FormLabel>Verification Code</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Verify</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}
