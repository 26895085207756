import styled, { css } from "styled-components";
import React, {useState, useEffect} from 'react'
import { device } from '../models/device';
import { useSpring, animated } from 'react-spring';

export const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5rem;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: none;
    border-radius: none;
    :hover {
        background: none;

        & svg {
        fill: ${props => props.light ? props.theme.button : "white"};
        stroke: ${props => props.light ? "white" : props.theme.button };
        stroke-width: 1px;
        }
    }
    & svg {
        fill: ${props => props.light ? "white" : props.theme.button };
    }
`;

export const StyledIcon = styled.div.attrs(props => ({
    className: props.Icon || 'img',
  }))`

`;

function buttonContent(props, setIsHovered) {
    return(
        <StyledButton type="button" light={props.light} value={props.value} onChange={props.handleChange} onClick={props.handleClick} onMouseEnter={ () => {setIsHovered(true);}} onMouseLeave={ () => {setIsHovered(false);}}>
        {props.children}
        </StyledButton>
    );
}

export default function IconButton(props) {
    const [isHovered, setIsHovered] = React.useState(false);
    const hoverAnimStyle = useSpring({
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        config: { mass: 1, tension: 500, friction: 80 }
        });
    useEffect(() => {
        // button animation on hover using react spring
        
      }, []);
    return(
        <animated.div style={hoverAnimStyle}>
            {buttonContent(props, setIsHovered)}
        </animated.div>
    )
}