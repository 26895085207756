import { React, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { SignInForm, SignIn2FAForm } from "../../../components/account/authentication/SignInForm";
import {
    requestSignIn,
    requestVerifyEmail,
} from "../../../components/account/RequestHandlers";
import { LoadingForm } from "../../../components/account/authentication/LoadingForm";

export default function SignIn(props) {
    const [verify, setVerify] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const handleSignInSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            email: data.get("email"),
            password: data.get("password"),
        };
        const response = await requestSignIn(payload);
        if (response) {
            if (response.ok) {
                setVerify(true);
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                console.log(result);
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleVerifyEmailSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            code: data.get("code"),
        };
        const response = await requestVerifyEmail(payload);
        if (response) {
            if (response.ok) {
                setErrorMsg(null);
                window.location.href = "./";
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const [loading, setLoading] = useState(true);
	useEffect(() => {
		const abortController = new AbortController();
		const checkAuthStatus = async () => {
            const isAuth = await props.onAuthStatusRequest(abortController.signal);
            if (isAuth) window.location.href = "./";
            else setLoading(false);	
		}
		checkAuthStatus();
		return () => abortController.abort();
	}, []);

    return (
        <>
            {loading ? (
                <LoadingForm />
            ) : (
                <>
                    {verify ? (
                        <SignIn2FAForm
                            handleSubmit={handleVerifyEmailSubmit}
                            errorMsg={errorMsg}
                            loading={submitting}
                        />
                    ) : (
                        <SignInForm
                            handleSubmit={handleSignInSubmit}
                            errorMsg={errorMsg}
                            loading={submitting}
                        />
                    )}
                </>
            )}
        </>
    );
}
