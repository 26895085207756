import { React, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ChangePhoneForm, ChangePhoneVerifyForm, ChangePhoneSuccessForm } from "../../components/account/authentication/ChangePhoneForm";
import {
    requestChangePhone,
    requestChangePhoneVerify
} from "../../components/account/RequestHandlers";
import { AccountContext } from "../../App";
import { LoadingForm } from "../../components/account/authentication/LoadingForm";

export default function ChangePhone(props) {
    const [verify, setVerify] = useState(false);
    const [changed, setChanged] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [phone, setPhone] = useState("");

    const handleChangePhoneSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            phone: data.get("phone"),
        };
        // remove format characters from phone, keep only '+' and digits
        payload.phone = payload.phone?.replace(/[^\d\+]+/g, "");
        setPhone(payload.phone);
        const response = await requestChangePhone(payload);
        if (response) {
            if (response.ok) {
                setVerify(true);
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleChangePhoneVerifySubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const data = new FormData(e.currentTarget);
        const payload = {
            phone: `${phone}`,
            code: data.get("code"),
        };
        const response = await requestChangePhoneVerify(payload);
        if (response) {
            if (response.ok) {
                setChanged(true)
                setErrorMsg(null);
                setSubmitting(false);
            } else {
                const result = await response.json();
                setErrorMsg(result.message);
                setSubmitting(false);
                return false;
            }
        } else {
            setErrorMsg("System is currently unavailable. Please try again later.");
            setSubmitting(false);
            return false;
        }
    };

    const handleBackToAccountSubmit = async (e) => {
        e.preventDefault();
        window.location.href = "/account";
    };

    const [loading, setLoading] = useState(true);
    const { authenticated } = useContext(AccountContext);
	useEffect(() => {
		const abortController = new AbortController();
		const checkAuthStatus = async () => {
            if (!authenticated) await props.onAuthStatusRequest(abortController.signal);
            setLoading(false);	
		}
		checkAuthStatus();
		return () => abortController.abort();
	}, []);

    return (
        <>
            {loading ? (
                <LoadingForm />
            ) : (
                <>
                    {authenticated ? (
                        <> 
                            {changed ? (
                                <ChangePhoneSuccessForm
                                    handleSubmit={handleBackToAccountSubmit}
                                    errorMsg={errorMsg}
                                    loading={submitting}
                                />
                            ) : (
                                <>
                                    {verify ? (
                                        <ChangePhoneVerifyForm
                                            handleSubmit={handleChangePhoneVerifySubmit}
                                            errorMsg={errorMsg}
                                            loading={submitting}
                                        />
                                    ) : (
                                        <ChangePhoneForm
                                            handleSubmit={handleChangePhoneSubmit}
                                            errorMsg={errorMsg}
                                            loading={submitting}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Navigate to="/account/sign-in" replace={true} />
                    )}
                </>
            )}
        </>
    );
}
