import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PillButton from "./PillButton";
import * as PopupStyles from "./style/PopupStyles";

const AnimatedEllipsisTitle = styled.h1`


`

const ProgressBar = styled.div`
    width: ${(props) => props.progress}%;
    height: 100%;
    background: ${(props) => props.theme.panelDark};
    border-radius: 2px;
`

export default function DownloadPopup(props) {
    const [progress, setProgress] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        if(isMounted)
            setIsPopupOpen(true);
        setIsMounted(true);
    }, [props.popupOpener]);
    
    return (
        <PopupStyles.PopupBGDiv isOpen={isPopupOpen}>
            <PopupStyles.PopupBoxDiv>
                <PopupStyles.PopupTitle>{props.title}</PopupStyles.PopupTitle>
                { props.children }
                <PillButton value={"Close"} handleClick={() => {setIsPopupOpen(false)}}></PillButton>
            </PopupStyles.PopupBoxDiv>
        </PopupStyles.PopupBGDiv>
    );
}
