import React from 'react'
import styled from "styled-components"
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom'
import Header from '../components/Header';
import ScrollToTop from '../components/ScrollToTop';
import { device } from '../models/device';
const StyledDiv = styled.div`
    display: flex;
    padding: 0;
    width: 100%;
    align-items: left;
    flex-direction: row;
    @media ${device.mobile} {
        flex-direction: column;
    }
`;

const SideBarBgDiv = styled.div` 
    background: ${props => props.theme.panel};
    width: 20vw;
    width: max(200px, 20vw, calc(250px + (100vw - 1250px) / 2)); 
    display: flex;
    flex-direction: column;
    height: calc(var(--app-height) - 96px - 75px);
    height: 100%;
    @media ${device.mobile} {
        width: 100vw;
    }
`

const SideBarDiv = styled.div`
    width: max(200px, 20vw);
    max-width: 250px;
    margin-left: auto;
    left: max(0px, calc((100vw - 1250px) / 2));
    position: fixed;
    @media ${device.mobile} {
        width: 100vw;
        max-width: 100vw;
        margin-left: 0;
    }
`;

const ContentDiv = styled.div`
    width: 80vw;
    max-width: 1000px;
    //min-width: 600px;
    @media ${device.mobile} {
        width: 100vw;
    }
`;

export default function ResourcesDocumentation() {   
    return (
        <>
        <ScrollToTop/>
            <Header title='Documentation' />
            <StyledDiv>    
                <SideBarBgDiv>
                    <SideBarDiv>
                        <Sidebar />
                    </SideBarDiv>
                </SideBarBgDiv>
                <ContentDiv>
                    <Outlet /> 
                </ContentDiv>
            </StyledDiv>
        </>
      
    );
}
