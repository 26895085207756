import React, { useState, useEffect, createContext } from 'react'

import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from './globalStyles'

import Navbar from './components/Navbar'

import Home from './pages/Home'
import CreatorAppDownload from './pages/CreatorAppDownload'
import SDKDownload from './pages/SDKDownload'
import ResourcesDocumentation from './pages/ResourcesDocumentation'
import ResourcesHome from './pages/ResourcesHome';
import ResourcesTutorials from './pages/ResourcesTutorials'
import ResourcesFAQ from './pages/ResourcesFAQ'
import AboutUsPinscreen from './pages/AboutUsPinscreen'
import Account from './pages/account/Account'
import AccountChangePlan from './pages/account/subscription/AccountChangePlan';
import AccountCreatePlan from './pages/account/subscription/AccountCreatePlan';
import SignIn from './pages/account/authentication/SignIn';
import SignUp from './pages/account/authentication/SignUp';
import ForgotPassword from './pages/account/authentication/ForgotPassword';
import ChangePassword from './pages/account/ChangePassword';
import ChangePhone from './pages/account/ChangePhone';
import ChangeEmail from './pages/account/ChangeEmail';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Error from './pages/Error';

import Footer from './components/Footer'
import MarkdownDocumentation from './pages/MarkdownDocumentation';

import {BrowserRouter as Router, Routes, Route, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider, ScrollRestoration} from 'react-router-dom'
import { StatusCodes } from "http-status-codes";
import './App.css'

import UnityMarkdown from './content/sdk_docs/unity/README.md'
import UnrealMarkdown from './content/sdk_docs/unreal/README.md'
import Terms from './content/legal/terms.txt'
import Privacy from './content/legal/privacy.txt'
import { Plans } from "./components/account/subscription/Plans";
import {
  requestProfile,
  requestPaymentMethod,
  requestBalance,
  requestSubscription,
  requestSubscriptionPreview
} from "./components/account/RequestHandlers";
import PurchasedPlan from './pages/account/redirects/PurchasedPlan';
import CanceledPurchase from './pages/account/redirects/CanceledPurchase';
import UnpaidSubscription from './pages/account/redirects/UnpaidSubscription';

const theme = {
  primary: '#f5f5f5',
  primaryVariant: '#e5e5e5',
  secondary: 'white',
  secondaryVariant: '#f5f5f5',
  text: 'hsl(0, 0%, 9%)',
  button: 'hsl(0, 0%, 9%)',
  buttonVariant: '#fff',
  buttonHover: '#fff',
  buttonHoverVarient: '#d5d5d5',
  panel: '#f5f5f5',
  panelVariant: '#d9d9d9',
  panelDark: 'hsl(0, 0%, 9%)',
  panelText: '#878787',
  panelTextVariant: '#a0a0a0',
  subPanel: '#ededed',
  accent: '#1068bf',
  accentVariant: '#5c9fe0',
}

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(var(--app-height) - 96px - 75px);
    max-width: 100vw;
`;

export const AccountContext = createContext();

function App() {
  const [unity_markdown, setUnityMarkdown] = useState("");
  const [unreal_markdown, setUnrealMarkdown] = useState("");
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");

  useEffect(() => {
    fetch(UnityMarkdown)
      .then((res) => res.text())
      .then((md) => setUnityMarkdown(md));
  }, []);

  useEffect(() => {
    fetch(UnrealMarkdown)
      .then((res) => res.text())
      .then((md) => setUnrealMarkdown(md));
  }, []);

  useEffect(() => {
    fetch(Terms)
      .then((res) => res.text())
      .then((txt) => setTerms(txt));
  }, []);

  useEffect(() => {
    fetch(Privacy)
      .then((res) => res.text())
      .then((txt) => setPrivacy(txt));
  }, []);

  const appHeight = () => {
    const doc = document.documentElement
    const barHeight = window.screen.height - window.screen.availHeight;
    //console.log("screenheight"+window.screen.height+"screenavilheight"+window.screen.availHeight+"innerhight"+window.innerHeight);
    var ua = window.navigator.userAgent;
    if(ua.match(/iPad/i) || ua.match(/iPhone/i) || ua.match(/Android|webOS|iPhone|iPod|Blackberry/i)){
      doc.style.setProperty('--app-height', `${window.innerHeight - barHeight}px`)
    }else{
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
  }

  const appWidth = () => {
    const doc = document.documentElement
    const barWidth = window.screen.width - window.screen.availWidth;
    //console.log("screenheight"+window.screen.height+"screenavilheight"+window.screen.availHeight+"innerhight"+window.innerHeight);
    var ua = window.navigator.userAgent;
    if(ua.match(/iPad/i) || ua.match(/iPhone/i) || ua.match(/Android|webOS|iPhone|iPod|Blackberry/i)){
      doc.style.setProperty('--app-width', `${window.innerWidth - barWidth}px`)
    }else{
      doc.style.setProperty('--app-width', `${window.innerWidth}px`)
    }
  }
  
  window.addEventListener('resize', appHeight);
  appHeight();

  window.addEventListener('resize', appWidth);
  appWidth();

  const [successful, setSuccessful] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [profile, setProfile] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [balance, setBalance] = useState(null);
  const [prices, setPrices] = useState(null);

  const getProfile = async () => {
    const profileResponse = await requestProfile();
      if (profileResponse.ok) {
        const profileResult = await profileResponse.json();
        setProfile(profileResult);
      }
      else {
        setSuccessful(false);
        return false;
      }
  }

  const getPaymentMethod = async () => {
    const paymentMethodResponse = await requestPaymentMethod();
    if (paymentMethodResponse?.ok) {
      try {
        const paymentMethodResult = await paymentMethodResponse.json();
        switch (paymentMethodResult.type) {
          case "card":
            setPaymentMethod(paymentMethodResult["card"].brand.toUpperCase() + " •••• " + paymentMethodResult["card"].last4);
            break;
          case "us_bank_account":
            setPaymentMethod(paymentMethodResult["us_bank_account"].bank_name + " •••• " + paymentMethodResult["us_bank_account"].last4);
            break;
          default:
            setPaymentMethod(paymentMethodResult.type.replace(/_/g, ' ').toUpperCase());
            break;
        }
      } catch (e) {
        setPaymentMethod(null);
      }
    }
    else {
      setSuccessful(false);
      return false;
    }
  }

  const getAvatarBalance = async () => {
    const balanceResponse = await requestBalance();
      if (balanceResponse.ok) {
        const balanceResult = await balanceResponse.json();
        setBalance(balanceResult);
      }
      else {
        setSuccessful(false);
        return false;
      }
  }
  
  const getPrices = async () => {
		// get preview prices
		var prices = {};
		for (const tier of Plans.tiers) {
			prices[`${tier}`] = {};
			for (const period of Plans.periods) {
				if (tier && period) {
					const previewPayload = { tier: tier, period: period, upgrade_now: false };
					const previewResponse = await requestSubscriptionPreview(previewPayload);
					if (previewResponse.ok) {
						const previewResult = await previewResponse.json();
						prices[`${tier}`][`${period}`] = previewResult;
					}
					else {
						setSuccessful(false);
            return false;
					}	
				}
			}
		}
		setPrices(prices);
	}

  const getAuthenticationStatus = async(signal) => {
    const response = await requestSubscription(signal);
    if (response == false) {
      setSuccessful(false);
      return false;
    }
    setSuccessful(true);
    const isAuth = response.status != StatusCodes.UNAUTHORIZED;
    setAuthenticated(isAuth);
    return isAuth;
  }

  const requestAccountInfo = async (signal) => {
		const response = await requestSubscription(signal);
    if (response == false) {
      setSuccessful(false);
      return false;
    }
		switch (response.status) {
      case StatusCodes.UNAUTHORIZED:
        setSuccessful(true);
        break;
			case StatusCodes.FORBIDDEN: { // no existing subscription
				setAuthenticated(true);
        setSubscription(null);
        await getProfile();
        await getPaymentMethod();
        await getAvatarBalance();
				await getPrices();
        setSuccessful(true);
				break;
			}
			case StatusCodes.OK: {
        setAuthenticated(true);
				const result = await response.json();
				setSubscription(result);
        await getProfile();
        await getPaymentMethod();
        await getAvatarBalance();
				await getPrices();
        setSuccessful(true);
				break;
			}
			default: {
				setSuccessful(false);
        return false;
			}
		}
	}

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<><Navbar/><StyledBody><Outlet/></StyledBody><Footer/></> } errorElement={<><Navbar/><StyledBody><Error/></StyledBody><Footer/></>} >
          <Route path='' element={<Home/>} />
          <Route path='creator-app-download' element={<CreatorAppDownload/>} />
          <Route path='sdk-download' element={<SDKDownload/>} />
          <Route path='resources-documentation' element={<ResourcesDocumentation/>} >
            <Route path='' element={<ResourcesHome/>} />
            <Route path='unity' element={<MarkdownDocumentation value={unity_markdown}/>} />
            <Route path='unreal' element={<MarkdownDocumentation value={unreal_markdown}/>} />
          </Route>        
          <Route path='resources-tutorials' element={<ResourcesTutorials/>} />
          <Route path='resources-faq' element={<ResourcesFAQ/>} />
          <Route path='about-us-pinscreen' element={<AboutUsPinscreen/>} />
          <Route path='terms-of-use' element={<TermsOfUse value={terms}/>} />
          <Route path='privacy-policy' element={<PrivacyPolicy value={privacy}/>} />
          <Route path='account' element={<Account onAccountRequest={requestAccountInfo}/>} />
          <Route path='account/create-plan' element={<AccountCreatePlan onAccountRequest={requestAccountInfo}/>} />
          <Route path='account/change-plan' element={<AccountChangePlan onAccountRequest={requestAccountInfo}/>} />
          <Route path='account/purchased-plan' element={<PurchasedPlan/>}/>
          <Route path='account/canceled-purchase' element={<CanceledPurchase/>} />
          <Route path='account/unpaid-subscription' element={<UnpaidSubscription/>} />
        </Route> 
        <Route path='/account/sign-in' element={<SignIn onAuthStatusRequest={getAuthenticationStatus}/>} exact/>
        <Route path='/account/sign-up' element={<SignUp onAuthStatusRequest={getAuthenticationStatus}/>} exact/>
        <Route path='/account/forgot-password' element={<ForgotPassword onAuthStatusRequest={getAuthenticationStatus}/>} exact/>
        <Route path='/account/change-password' element={<ChangePassword onAuthStatusRequest={getAuthenticationStatus}/>} exact/>
        <Route path='/account/change-phone' element={<ChangePhone onAuthStatusRequest={getAuthenticationStatus}/>} exact/>
        <Route path='/account/change-email' element={<ChangeEmail onAuthStatusRequest={getAuthenticationStatus}/>} exact/>
      </>
    )
  );

  return (
    <AccountContext.Provider value={{ 
      successful, setSuccessful, 
      authenticated, setAuthenticated, 
      profile, setProfile,
      paymentMethod, setPaymentMethod,
      subscription, setSubscription, 
      balance, setBalance, 
      prices, setPrices }}>
      <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <RouterProvider router={router} />
      </ThemeProvider>
    </AccountContext.Provider>
  );
}

export default App
