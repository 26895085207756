import styled from "styled-components";
import React, { useState, useEffect, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import { formatPhoneNumber } from 'react-phone-number-input'
import Header from "../../components/Header";
import {
	requestToken,
	requestSubscriptionResume,
	requestBilling,
	requestLogout,
} from "../../components/account/RequestHandlers";
import LoadingWheel from "../../components/account/LoadingWheel";
import PillButton from "../../components/PillButton";
import { AccountContext } from "../../App";
import DownloadPopup from "../../components/Popup";
import { delay } from "q";
import AccountError from "./AccountError";

import * as AccountStyles from "../../components/style/AccountStyles";
import * as PopupStyles from "../../components/style/PopupStyles";

const StyledVerticalDiv = styled.div`
	height: auto;
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
`;

const StyledHorizDiv = styled.div`
	font-size: 1rem;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 12px;
`;

const StyledDownloadPopup = styled(DownloadPopup)`
	gap: 12px;
`;

function ProfileSection({ handleBillingSubmit }) {
	const { profile, paymentMethod } = useContext(AccountContext);
	return (
		<AccountStyles.InfoSectionDiv>
			<AccountStyles.InfoSectionHeaderDiv>
				<h1>Profile</h1>
			</AccountStyles.InfoSectionHeaderDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Name</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					<AccountStyles.InfoEntryContent>{profile.name}</AccountStyles.InfoEntryContent>
				</AccountStyles.InfoEntryContentDiv>
				<AccountStyles.InfoEntryButton to={""}>	
				</AccountStyles.InfoEntryButton>
			</AccountStyles.InfoEntryDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Email</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					<AccountStyles.InfoEntryContent>{profile.email}</AccountStyles.InfoEntryContent>
				</AccountStyles.InfoEntryContentDiv>
				<AccountStyles.InfoEntryButton to={"/account/change-email"}>
					Change Email
				</AccountStyles.InfoEntryButton>
			</AccountStyles.InfoEntryDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Phone Number</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					<AccountStyles.InfoEntryContent>{formatPhoneNumber(profile.phone)}</AccountStyles.InfoEntryContent>
				</AccountStyles.InfoEntryContentDiv>
				<AccountStyles.InfoEntryButton to={"/account/change-phone"}>
					Change Phone Number
				</AccountStyles.InfoEntryButton>
			</AccountStyles.InfoEntryDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Password</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					<AccountStyles.InfoEntryContent>••••••••</AccountStyles.InfoEntryContent>
				</AccountStyles.InfoEntryContentDiv>
				<AccountStyles.InfoEntryButton to={"/account/change-password"}>
					Change Password
				</AccountStyles.InfoEntryButton>
			</AccountStyles.InfoEntryDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Payment Method</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					{paymentMethod ? 
						<>
							<AccountStyles.InfoEntryContent>{paymentMethod}</AccountStyles.InfoEntryContent>
							<AccountStyles.InfoEntryContentNote>(Default)</AccountStyles.InfoEntryContentNote>
						</> :
						<>
							<AccountStyles.InfoEntryContent>No payment method</AccountStyles.InfoEntryContent>
						</>
					}
				</AccountStyles.InfoEntryContentDiv>
				<AccountStyles.InfoEntryButton to={""} onClick={handleBillingSubmit}>
					Manage
				</AccountStyles.InfoEntryButton>
			</AccountStyles.InfoEntryDiv>
		</AccountStyles.InfoSectionDiv>
	)
}

function SubscriptionSectionWithPlan( {handleBillingSubmit, handleSubscriptionResumeSubmit} ) {
	const { subscription } = useContext(AccountContext);
	return (
		<AccountStyles.InfoSectionDiv>
			<AccountStyles.InfoSectionHeaderDiv>
				<h1>Subscription</h1>
			</AccountStyles.InfoSectionHeaderDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Current Plan</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					<AccountStyles.InfoEntryContent>
						{subscription.invoiced.name}
					</AccountStyles.InfoEntryContent>
					{subscription.invoiced.status === "uncollectible" ? 
					<AccountStyles.InfoEntryContentNote>Fail To Process Payment - <button onClick={handleBillingSubmit}>See Billing Portal</button></AccountStyles.InfoEntryContentNote> : 
					<>
					{subscription.invoiced.status === "open" ? 
					<AccountStyles.InfoEntryContentNote>Payment Pending - <button onClick={handleBillingSubmit}>See Billing Portal</button></AccountStyles.InfoEntryContentNote> :
					<AccountStyles.InfoEntryContentNote>Plan ends: {new Date(subscription.upcoming.bills_on * 1000).toLocaleDateString()}</AccountStyles.InfoEntryContentNote>}
					</>}
				</AccountStyles.InfoEntryContentDiv>
				{subscription.upcoming.canceling ? (
					<AccountStyles.InfoEntryButton to={""}></AccountStyles.InfoEntryButton>
				) : (											
					<AccountStyles.InfoEntryButton to={"/account/change-plan"}>
						Change
					</AccountStyles.InfoEntryButton>
				)}
			</AccountStyles.InfoEntryDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Renewal Plan</AccountStyles.InfoEntryLabel>
				{subscription.upcoming.canceling ? (
					<>
						<AccountStyles.InfoEntryContentDiv>
							<AccountStyles.InfoEntryContent red>Canceled</AccountStyles.InfoEntryContent>
						</AccountStyles.InfoEntryContentDiv>
						<AccountStyles.InfoEntryButton
							to={""}
							onClick={handleSubscriptionResumeSubmit}
						>
							Resume
						</AccountStyles.InfoEntryButton>
					</>
				) : (
					<>
						<AccountStyles.InfoEntryContentDiv>
							<AccountStyles.InfoEntryContent>{subscription.upcoming.name}</AccountStyles.InfoEntryContent>
							<AccountStyles.InfoEntryContentNote>Amount Due: {subscription.upcoming.display.amount_due}</AccountStyles.InfoEntryContentNote>
						</AccountStyles.InfoEntryContentDiv>
						<AccountStyles.InfoEntryButton to={""}>
						</AccountStyles.InfoEntryButton>
					</>
				)}
			</AccountStyles.InfoEntryDiv>
		</AccountStyles.InfoSectionDiv>
	)
}

function SubscriptionSectionWithoutPlan() {
	return (
		<AccountStyles.InfoSectionDiv>
			<AccountStyles.InfoSectionHeaderDiv>
				<h1>Subscription</h1>
			</AccountStyles.InfoSectionHeaderDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Current Plan</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					<AccountStyles.InfoEntryContent red>Not Subscribed</AccountStyles.InfoEntryContent>
				</AccountStyles.InfoEntryContentDiv>
				<AccountStyles.InfoEntryButton to={"/account/create-plan"}>
					Get Started
				</AccountStyles.InfoEntryButton>
			</AccountStyles.InfoEntryDiv>
		</AccountStyles.InfoSectionDiv>
	)
}

function SubscriptionSection( {handleBillingSubmit, handleSubscriptionResumeSubmit} ) {
	const { subscription } = useContext(AccountContext);
	return (
		<>
			{subscription ? 
			<SubscriptionSectionWithPlan handleBillingSubmit={handleBillingSubmit} handleSubscriptionResumeSubmit={handleSubscriptionResumeSubmit} /> : 
			<SubscriptionSectionWithoutPlan />
			}
		</>
	)
}

function AvatarBalanceSection({ handleTokenSubmit }) {
	const { balance } = useContext(AccountContext);
	return (
		<AccountStyles.InfoSectionDiv>
			<AccountStyles.InfoSectionHeaderDiv>
				<h1>Avatar Balance</h1>
			</AccountStyles.InfoSectionHeaderDiv>
			<AccountStyles.InfoEntryDiv>
				<AccountStyles.InfoEntryLabel>Remaining Avatars</AccountStyles.InfoEntryLabel>
				<AccountStyles.InfoEntryContentDiv>
					<AccountStyles.InfoEntryContent>{balance.capacity.toLocaleString()}</AccountStyles.InfoEntryContent>
					<AccountStyles.InfoEntryContentNote>Expires: {new Date(balance.expiration_date * 1000).toLocaleDateString()}</AccountStyles.InfoEntryContentNote>
				</AccountStyles.InfoEntryContentDiv>
				<AccountStyles.InfoEntryButton to={""} onClick={handleTokenSubmit}>
					Get Token
				</AccountStyles.InfoEntryButton>
			</AccountStyles.InfoEntryDiv>
		</AccountStyles.InfoSectionDiv>
	)
}

function BugsAndFeedbackSection() {
	return (
		<AccountStyles.InfoSectionDiv>
			<AccountStyles.InfoSectionHeaderDiv>
				<h1>Bugs and Feedback</h1>
			</AccountStyles.InfoSectionHeaderDiv>
			<AccountStyles.InfoEntryContent>
				This is a beta of our API and SDK. 
				If you experience a bug or want to give us feedback please do so through 
				this <AccountStyles.InfoEntryButton to={"https://docs.google.com/forms/d/e/1FAIpQLSdFasOa-5KcDOWI9SAwFHiGxSX23dCNXkTepoEBgpLhg95Kqg/viewform?usp=sharing"}>form</AccountStyles.InfoEntryButton>
				. Your reports are appreciated. It will help us make Avatar Neo even better!
			</AccountStyles.InfoEntryContent>						
		</AccountStyles.InfoSectionDiv>
	)
}

function TokenPopup({ token, tokenPopupOpener }) {
	const [showPopupToken, setShowPopupToken] = useState(false);
	const [showIsCopied, setShowIsCopied] = useState(false);
	const toggleShowPopupToken = () => {
		setShowPopupToken(!showPopupToken);
	};

	const copyToken = async (token) => {
		await navigator.clipboard.writeText(token);
		setShowIsCopied(true);
		await delay(1000);
		setShowIsCopied(false);
	};

	return (
		<StyledDownloadPopup title={'Token'} popupOpener={tokenPopupOpener}>
			<StyledVerticalDiv>
				{ showPopupToken ? 
					<PopupStyles.PopupCode>
						{token ? token : "Failed to retrieve token. Please contact support or submit a bug report."}
						{ showIsCopied ? <PopupStyles.PopupTextOverlap>Copied to Clipboard!</PopupStyles.PopupTextOverlap> : <></>}
					</PopupStyles.PopupCode> : 
					<PopupStyles.PopupCode>
						{token ? token.replace(/./g, '\u2022') : "Failed to retrieve token. Please contact support or submit a bug report."}
						{ showIsCopied ? <PopupStyles.PopupTextOverlap>Copied to Clipboard!</PopupStyles.PopupTextOverlap> : <></>}
					</PopupStyles.PopupCode>
				}
				<StyledHorizDiv>
					<PillButton value={showPopupToken ? "Hide Token" : "Show Token"} handleClick={toggleShowPopupToken}>
					</PillButton>
					<PillButton value={"Click to Copy"} handleClick={() => copyToken(token ? token : "")}>
					</PillButton>
				</StyledHorizDiv>
			</StyledVerticalDiv>
		</StyledDownloadPopup>
	)
}

export default function Account(props) {
	const { successful, setSuccessful, authenticated } = useContext(AccountContext);

	const handleBillingSubmit = async () => {
		const response = await requestBilling();
		if (response.ok) {
			const result = await response.json();
			window.location.href = result.url;
		} else {
			setSuccessful(false);
			return false;
		};
	};

	const handleSubscriptionResumeSubmit = async () => {
		const response = await requestSubscriptionResume();
		if (response.ok) {
			window.location.reload();
		} else {
			setSuccessful(false);
			return false;
		}
	};

	const handleLogoutSubmit = async () => {
		const response = await requestLogout();
		if (!response.ok) {
			setSuccessful(false);
			return false;
		}
		window.location.href = ".././";
	};

	const [token, setToken] = useState("");
	const [tokenPopupOpener, setTokenPopupOpener] = useState(false);	
	const handleTokenSubmit = async () => {
		const response = await requestToken();
		if (response.ok) {
			const { sdk_token } = await response.json();
			setToken(sdk_token);
			setTokenPopupOpener(!tokenPopupOpener);
		} else {
			setSuccessful(false);
			return false;
		}
	};
	
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const abortController = new AbortController();
		const loadAccountInfo = async () => {
			if (!authenticated) await props.onAccountRequest(abortController.signal);
			setLoading(false);	
		}
		loadAccountInfo();
		return () => abortController.abort();
	}, []);

	return (
		<>
			<Header title="Account" />
			{loading ? (
				<LoadingWheel />
			) : (
				<>
					{successful ? (
						<>
							{authenticated ? (
								<AccountStyles.ContentDiv>
									<ProfileSection handleBillingSubmit={handleBillingSubmit} />
									<SubscriptionSection handleBillingSubmit={handleBillingSubmit} handleSubscriptionResumeSubmit={handleSubscriptionResumeSubmit} />
									<AvatarBalanceSection handleTokenSubmit={handleTokenSubmit} />
									<BugsAndFeedbackSection />
									<PillButton
										dark
										value={"Log out"}
										handleClick={handleLogoutSubmit}
									></PillButton>
									<br />
								</AccountStyles.ContentDiv>
							) : (
								<Navigate to="/account/sign-in" replace={true} />
							)}
						</>
					) : (
						<AccountError/>
					) }
				</>
			)}
			<TokenPopup token={token} tokenPopupOpener={tokenPopupOpener}/>
		</>
	);
}
