import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

const StyledButton = styled(Link)`
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 15px;
    padding-left: 2rem;
    color: ${props => props.theme.panelText};
    background: ${props => props.selected ? props => props.theme.panelVariant : ''};
    font-weight: ${props => props.selected ? 'bold' : ''};
`;

const StyledSubMenuButton = styled.a`
    background: ${props => props.theme.subPanel};
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-left: 2rem;
    li {
        color: ${props => props.theme.panelText};
    }
`;

let sidebarData = [
    {
        name: 'Unity',
        to: '/resources-documentation/unity',
        subMenu: [
            {
                name: 'Installation',
                to: '#installation',
            },
            {
                name: 'Usage',
                to: '#usage',
            },
            {
                name: 'Demo',
                to: '#demo',
            },
        ]
    },
    {
        name: 'Unreal',
        to: '/resources-documentation/unreal',
        subMenu: [
            {
                name: 'Installation',
                to: '#installation',
            },
            {
                name: 'Usage',
                to: '#usage',
            },
            {
                name: 'Demo',
                to: '#demo',
            },
        ]
    }
]

export default function Sidebar(props) {
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
    const setScrollheight = (height) => {
        console.log(height);
        window.scrollTo({top: height, left: 0, behavior: 'smooth'});
    }
    const location = useLocation();

    const setSelectedButtonIndexFromPath = () => {
        console.log(window.location.pathname);
        switch(window.location.pathname){
            case '/resources-documentation/unity':
                console.log('unity');
                setSelectedButtonIndex(0);
                break;
            case '/resources-documentation/unreal':
                console.log('unreal');
                setSelectedButtonIndex(1);
                break;
            default:
                console.log('default');
                setSelectedButtonIndex(null);
                break;
        }
    }

    // make sure page setup correctly if loaded from url or link
    useEffect(() => {
        setSelectedButtonIndexFromPath();
      }, []);

          // make sure page setup correctly if route changes
    useEffect(() => {
        setSelectedButtonIndexFromPath();
      }, [location]);

    return (
        <>
            <StyledButton 
                to={'/resources-documentation'} onClick={e => setSelectedButtonIndex(null)}
                selected={selectedButtonIndex === null ? true : false}
                style={{
                    color: '#252525',
                    fontWeight: 'bold',
                }}
            >
                Documentation
            </StyledButton>
            {sidebarData.map((item, index) => {
                return (
                    <>
                        <StyledButton 
                            key={index} to={item.to} onClick={e => setSelectedButtonIndex(index)}
                            selected={selectedButtonIndex === index ? true : false}
                        >
                            {item.name}
                        </StyledButton>                  
                        {item.subMenu && selectedButtonIndex === index ? item.subMenu.map((subItem, subIndex) => {
                            return (
                                <StyledSubMenuButton href={subItem.to}>
                                    <li>{subItem.name}</li>
                                </StyledSubMenuButton>
                            )
                        }) : null}
                    </>
                )
            })}
        </>
    )
}