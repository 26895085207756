import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContentDiv = styled.div`
	height: 100%;
	width: min(${(props) => props.width}, 90vw);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

ContentDiv.defaultProps = {
    width: "1000px"
};

export const BoxButton = styled.button`
    width: 150px;
    height: 80px;
    background: ${(props) =>
        props.selected
            ? (props) => props.theme.text
            : (props) => props.theme.primary};
    color: ${(props) =>
        props.selected
            ? (props) => props.theme.primary
            : (props) => props.theme.text};
    border: 2px solid ${(props) => props.theme.text};
    border-radius: 7px;
    font-size: 1rem;
    font-weight: bold;
    &[disabled] {
        opacity: 0.4;
        :hover {
            background: ${(props) =>
                props.selected
                    ? (props) => props.theme.text
                    : (props) => props.theme.primary};
        }
    }
    :hover {
        background: ${(props) =>
            props.selected
                ? (props) => props.theme.text
                : (props) => props.theme.primaryVariant};
    }
`

export const BoxButtonText = styled.p`
  margin: 0;
`;

export const BoxButtonNote = styled.p`
  margin: 0;
  font-size: 0.7rem;
  font-weight: normal;
`;

export const PlanRowDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 1rem;
    gap: 1rem;
`;

export const PlanRowLabel = styled.p`
    width: 70%;
`;

export const InfoSectionDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    gap: .5rem;
`;

export const InfoSectionHeaderDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: .5em;
    margin-bottom: 10px;
    border-bottom: 2px solid lightgrey;
    a {
        color: ${(props) => props.theme.accent};
    }
`;

export const InfoEntryDiv = styled.div`
    width: 100%;
    height: auto;
    min-height: 45px;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const InfoEntryLabel = styled.p`
    flex-grow: 1;
	flex-basis: 30%;
	font-weight: bold;
	margin: 0;
`;

export const InfoEntryContentDiv = styled.div`
    flex-grow: 1;
    flex-basis: 50%;
    margin: 0;
    display: flex;
    flex-direction: column;
`;

export const InfoEntryContent = styled.p`
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${props => props.red ? "red" : ""};
`;

export const InfoEntryContentNote = styled.p`
    margin: 0;
    font-size: .9rem;
    font-style: italic;
    color: grey;
    button {
        padding: 0;
        margin: 0;
        font-size: .9rem;
        font-style: italic;
        background-color: transparent;
        text-decoration: none;
        color: grey;
        :hover {
            color: silver;
        }
    }
`;

export const InfoEntryButton = styled(Link)`
    flex-grow: 1;
    flex-basis: 30%;
    text-align: right;
    color: ${(props) => props.theme.accent};
    &:hover {
        color: ${(props) => props.theme.accentVariant};
    }
`;