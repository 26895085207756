import React from "react";
import * as FormStyles from "../../style/FormStyles";
import logo from "../../../images/landing/navigation/avatarneo.png";
import LoadingWheel from "../LoadingWheel";

export function ChangeEmailForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Update Email</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            Please enter your new email address.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput name="email" autocomplete="email" required />
                            <FormStyles.FormLabel>Email</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Next</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}

export function ChangeEmailVerifyForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Verify your email address</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            We've sent a 6-digit verification code to your email address. The code will expire in about 10 minutes.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormInputDiv>
                            <FormStyles.FormInput type="number" name="code" autocomplete="one-time-code" required />
                            <FormStyles.FormLabel>Verification Code</FormStyles.FormLabel>
                        </FormStyles.FormInputDiv>
                        <FormStyles.FormHelperText>
                            <a href='/account/change-email'>Back</a>
                        </FormStyles.FormHelperText>
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Verify</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}

export function ChangeEmailSuccessForm(props) {
    return (
        <FormStyles.FormDiv>
            <FormStyles.Form onSubmit={props.handleSubmit}>
                {props.loading ? (
                    <LoadingWheel />
                ) : (
                    <>
                        <FormStyles.FormLogoLink to={"/"}>
                            <img src={logo} />
                        </FormStyles.FormLogoLink>
                        <FormStyles.FormTitleText>Success!</FormStyles.FormTitleText>
                        <FormStyles.FormDescriptionText>
                            Your email address has been changed.
                        </FormStyles.FormDescriptionText>
                        {props.errorMsg && (
                            <FormStyles.FormErrorDiv>&#9888; {props.errorMsg}</FormStyles.FormErrorDiv>
                        )}
                        <FormStyles.FormSpacerDiv />
                        <FormStyles.FormButton type="submit">Back to Account</FormStyles.FormButton>
                    </>
                )}
            </FormStyles.Form>
        </FormStyles.FormDiv>
    );
}
