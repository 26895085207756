import React from 'react'
import Header from '../components/Header'
import styled from 'styled-components'

const StyledDiv = styled.div`
    width: 1000px;
    max-width: 100vw;
    padding: 20px;
    padding-bottom: 100px;
`

const StyledContent = styled.p`
    margin: 0;
    padding: .5rem;
`

export default function PrivacyPolicy(props) {
    return(
        <>
            <Header title='Privacy Policy' />
            <StyledDiv>
                {props.value.split('\n').map((line, index) => (
                    <StyledContent key={index}>{line}</StyledContent>
                ))}
            </StyledDiv>
        </>
    )
}