import React, {useState} from 'react'
import Header from '../components/Header'

export default function ResourcesFAQ() {
    return(
        <>
            <Header title='FAQ' />
            <p>Resources FAQ</p>
        </>
    )
}