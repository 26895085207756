import React, {useState} from 'react'
import Header from '../components/Header'

export default function ResourcesTutorials() {
    return(
        <>
            <Header title='Tutorials' />
            <p>Resources Tutorials</p>
        </>
    )
}